import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { useNavigate } from "react-router-dom";

function SettingsIndex() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [inputData, setInputData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    department: "",
    level: ""
  });
  const [activeField, setActiveField] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch user data from local storage
  useEffect(() => {
    const fetchUserData = () => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      return userData || {};
    };
    setUserData(fetchUserData());
  }, []);

  // Update input data when userData is loaded
  useEffect(() => {
    if (userData) {
      setInputData({
        fullName: userData.fullName || "",
        email: userData.email || "",
        phoneNumber: userData.phoneNumber || "",
        department: userData.department || "",
        level: userData.level || ""
      });
    }
  }, [userData]);

  // Handle Change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle Edit Features
  const handleEditData = (field) => {
    setActiveField(field);
  };

  // Handle Cancel Edit
  const handleCancel = () => {
  // Reset active field when "Cancel" is clicke
    setActiveField(""); 
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Simple input validation
    if (!inputData[activeField]) {
        toast.error("Please enter a value to update");
        return;
      }
    setLoading(true);
    const data = {
      [activeField]: inputData[activeField]
    };
    // console.log(data);
    const url = `${process.env.REACT_APP_UPDATE_USER}`;

    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization":`Bearer ${localStorage.getItem("login_token")}`
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      if (!response.ok) {
        throw new Error(resObj.message);
      }
      toast.success(resObj?.message);
      setLoading(false);
      setActiveField(""); // Reset active field after submission
      setTimeout(()=>{
       navigate("/dashboard");
       window.location.href = '/dashboard';
      },2000)
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };


//   Handle Account Delete
  const handleAccountDelete = async() => {
    setLoading(true)
    const url = `${process.env.REACT_APP_DELETE_USER}`;
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization":`Bearer ${localStorage.getItem("login_token")}`
      },
    };
    try{
        const response = await fetch(url, params);
        // const resObj = await response.json();
  
        // if (!response.ok) {
        //   throw new Error(resObj.message);
        // }
        setLoading(false);
        setActiveField(""); // Reset active field after submission
        localStorage.removeItem("isAuthenticated_1stclassmaterial_user");
        navigate("/login");
    }
    catch(err){
        toast.error(err.message)
        setLoading(false);
    }
  }

  return (
    <div className="personal-info-container">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          {/* Full Name field */}
          <div className="form-field">
            <label htmlFor="fullName">Full Name</label>
            <div className="data-container flex items-end mb-4">
              <div className="flex-item basis-[72%] mr-auto">
                <input
                  type="text"
                  value={inputData.fullName}
                  name="fullName"
                  id="fullName"
                  onChange={handleChange}
                  readOnly={activeField !== "fullName"}
                  className={activeField === "fullName" ? "active" : null}
                />
              </div>
              <div className="flex-item basis-[22%]">
                {activeField === "fullName" ? (
                  <span
                    className="capitalize font-[500] inline-block cursor-pointer"
                    onClick={handleCancel}
                  >
                    Cancel
                  </span>
                ) : (
                  <span
                    className="capitalize font-[500] inline-block cursor-pointer"
                    onClick={() => handleEditData("fullName")}
                  >
                    Edit
                  </span>
                )}
              </div>
            </div>
            {activeField === "fullName" && (
              <button className="cta-btn" type="submit">
                {loading ? <LoadingSpinner /> : "Save"}
              </button>
            )}
          </div>

          {/* Email field */}
          <div className="form-field">
            <label htmlFor="email">Email</label>
            <div className="data-container flex items-end mb-4">
              <div className="flex-item basis-[72%] mr-auto">
                <input
                  type="email"
                  value={inputData.email}
                  name="email"
                  id="email"
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          </div>

          {/* Phone Number field */}
          <div className="form-field">
            <label htmlFor="phoneNumber">Phone Number</label>
            <div className="data-container flex items-end mb-4">
              <div className="flex-item basis-[72%] mr-auto">
                <input
                  type="tel"
                  value={inputData.phoneNumber}
                  name="phoneNumber"
                  id="phoneNumber"
                  maxLength="11"
                  onChange={handleChange}
                  readOnly={activeField !== "phoneNumber"}
                  className={activeField === "phoneNumber" ? "active" : null}
                />
              </div>
              <div className="flex-item basis-[22%]">
                {activeField === "phoneNumber" ? (
                  <span
                    className="capitalize font-[500] inline-block cursor-pointer"
                    onClick={handleCancel}
                  >
                    Cancel
                  </span>
                ) : (
                  <span
                    className="capitalize font-[500] inline-block cursor-pointer"
                    onClick={() => handleEditData("phoneNumber")}
                  >
                    Edit
                  </span>
                )}
              </div>
            </div>
            {activeField === "phoneNumber" && (
              <button className="cta-btn" type="submit">
                {loading ? <LoadingSpinner /> : "Save"}
              </button>
            )}
          </div>

          {/* Department field */}
          <div className="form-field">
            <label htmlFor="department">Department</label>
            <div className="data-container flex items-end mb-4">
              <div className="flex-item basis-[72%] mr-auto">
                <input
                  type="text"
                  value={inputData.department}
                  name="department"
                  id="department"
                  onChange={handleChange}
                  readOnly={activeField !== "department"}
                  className={activeField === "department" ? "active" : null}
                />
              </div>
              <div className="flex-item basis-[22%]">
                {activeField === "department" ? (
                  <span
                    className="capitalize font-[500] inline-block cursor-pointer"
                    onClick={handleCancel}
                  >
                    Cancel
                  </span>
                ) : (
                  <span
                    className="capitalize font-[500] inline-block cursor-pointer"
                    onClick={() => handleEditData("department")}
                  >
                    Edit
                  </span>
                )}
              </div>
            </div>
            {activeField === "department" && (
              <button className="cta-btn" type="submit">
                {loading ? <LoadingSpinner /> : "Save"}
              </button>
            )}
          </div>

          {/* Level field */}
          <div className="form-field">
            <label htmlFor="level">Level</label>
            <div className="data-container flex items-end mb-4">
              <div className="flex-item basis-[72%] mr-auto">
                <input
                  type="text"
                  value={inputData.level}
                  name="level"
                  id="level"
                  onChange={handleChange}
                  readOnly={activeField !== "level"}
                  className={activeField === "level" ? "active" : null}
                />
              </div>
              <div className="flex-item basis-[22%]">
                {activeField === "level" ? (
                  <span
                    className="capitalize font-[500] inline-block cursor-pointer"
                    onClick={handleCancel}
                  >
                    Cancel
                  </span>
                ) : (
                  <span
                    className="capitalize font-[500] inline-block cursor-pointer"
                    onClick={() => handleEditData("level")}
                  >
                    Edit
                  </span>
                )}
              </div>
            </div>
            {activeField === "level" && (
              <button className="cta-btn" type="submit">
                {loading ? <LoadingSpinner /> : "Save"}
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="horizontal-line my-8">
      <hr />
      </div>
      <div className="form-field">
            <label htmlFor="department">Delete account</label>
            <div className="data-container flex items-end mb-4">
              <div className="flex-item basis-[72%] mr-auto">
                <span>Delete account permanently</span>
              </div>
              <div className="flex-item basis-[22%]">
                {activeField === "delete-account" ? (
                  <span
                    className="capitalize font-[500] text-[#d63c45] inline-block cursor-pointer"
                    onClick={handleCancel}
                  >
                    Cancel
                  </span>
                ) : (
                  <span
                    className="capitalize font-[500] text-[#d63c45] inline-block cursor-pointer"
                    onClick={() => handleEditData("delete-account")}
                  >
                    Delete
                  </span>
                )}
              </div>
            </div>
            {activeField === "delete-account" && (
              <button className="cta-btn" type="submit" onClick={handleAccountDelete}>
                {loading ? <LoadingSpinner /> : "Delete"}
              </button>
            )}
          </div>
    </div>
  );
}

export default SettingsIndex;
