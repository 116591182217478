import React from 'react'

const LevelIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M9.75 14.2356V7.48556H3V6.73556H10.5V14.2356H9.75ZM12.75 11.25V4.5H6V3.75H13.5V11.25H12.75Z" fill="#1F1F1F"/>
</svg>
  )
}

export default LevelIcon