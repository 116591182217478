import React, { useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import EyeIcon from '../../icons/EyeIcon';
import LoadingSpinner from '../../shared/LoadingSpinner';
import PasswordStrengthCheck from '../../Auth/PasswordStrengthCheck';

function Security() {
  const [user, setUser] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState(false);
  const [showPasswordStrengthCheck, setShowPasswordStrengthCheck] = useState(false); // New state for password strength check

  // Handle Password and confirm passwords
  const handleNewPassword = () => {
    setNewPassword((prev) => !prev);
  };

  const handleConfirmPassword = () => {
    setConfirmPassword((prev) => !prev);
  };

  const handleOldPassword = () => {
    setOldPassword((prev) => !prev);
  };

  // Handle Change
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      newPassword: user.newPassword,
      confirmPassword: user.confirmPassword,
      oldPassword: user.oldPassword,
    };
    const url = `${process.env.REACT_APP_UPDATE_USER_PASSWORD}`;
    const params = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('login_token')}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      if (!response.ok) {
        throw new Error(resObj.message);
      }
      toast.success(resObj?.message);
      setLoading(false);
      setUser((prev) => ({
        ...prev,
        oldPassword: '',
        newPassword: '',
        confirmPassword:""
      }));
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className='security-container'>
      <div className="form-container">
        <p className='font-[500]'>manage your password</p>
        <form onSubmit={handleSubmit}>
          <div className="form-field">
            <label htmlFor="oldPassword">old password</label>
            <input
              type={oldPassword ? 'text' : 'password'}
              name='oldPassword'
              value={user.oldPassword}
              onChange={handleChange}
            />
            <div className="eye-icon-container" onClick={handleOldPassword}>
              <EyeIcon />
            </div>
          </div>

          <div className="form-field">
            <label htmlFor="newPassword">new password</label>
            <input
              type={newPassword ? 'text' : 'password'}
              name='newPassword'
              value={user.newPassword}
              onChange={handleChange}
              onFocus={() => setShowPasswordStrengthCheck(true)} // Show password strength on focus
              onBlur={() => setShowPasswordStrengthCheck(false)} // Hide password strength on blur
            />
            <div className="eye-icon-container" onClick={handleNewPassword}>
              <EyeIcon />
            </div>
          </div>
          <div className="password-checker mb-4">
           {showPasswordStrengthCheck && ( // Conditionally render PasswordStrengthCheck
              <PasswordStrengthCheck passwordVal={user.newPassword} />
            )}
          </div>

          <div className="form-field">
            <label htmlFor="confirmPassword">confirm password</label>
            <input
              type={confirmPassword ? 'text' : 'password'}
              name='confirmPassword'
              value={user.confirmPassword}
              onChange={handleChange}
            />
            <div className="eye-icon-container" onClick={handleConfirmPassword}>
              <EyeIcon />
            </div>
          </div>

          <div className="form-field text-right">
            <button className='cta-btn' disabled={loading}>
              {loading ? <LoadingSpinner /> : 'change'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Security;
