import React from 'react'
import ModalWrapper from './ModalWrapper'
import StudyShareForm from '../Dashboard/StudyShare/StudyShareForm'


function AddMaterialModal({title,closeModal,closeModalIcon,footerBtns=[]}) {

  return (
    <ModalWrapper>
     <div className='add-material-container overflow-y-auto lg:overflow-y-hidden webkit-scrollbar h-[484px] rounded-[12px] bg-white p-[28px] w-[95%] lg:w-[989px]'>
        <div className="modal-header flex m-[24px] justify-between items-start">
        <h4>{title}</h4>
        <span 
         className='icon-container p-[0.1rem] cursor-pointer h-[27px] w-[27px] border border-[#a5a5a5] rounded-[50%]'  
         onClick={closeModal}>
         {closeModalIcon}
        </span>
        </div>

         <StudyShareForm footerBtns={footerBtns} closeModal={closeModal}/>
    </div>
    </ModalWrapper>
  )
}

export default AddMaterialModal