import React from 'react'

function Services() {
  return (
    <div className='mt-20'>
      <p>Services</p>
    </div>
  )
}

export default Services