import React from 'react'
import FeedbackIcon from '../icons/FeedbackIcon'
import FeedBackIndex from '../Feedback/FeedBackIndex'

function Feedback() {
  return (
    <div className='mt-20'>
      <div className="mb-[8px]">
      <FeedbackIcon/>
      </div>
      <h4>Feedback</h4>
      <FeedBackIndex/>
    </div>
  )
}

export default Feedback