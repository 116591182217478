import React from 'react'
import FilterIcon from '../Dashboard/icons/FilterIcon'

function TabSelector({
    tabs,
    activeTab,
    handleClick,
}) {
  return (
    <div className='btn-container mb-4 w-[380px] md:w-[100%] overflow-auto'>
        <div className='inlineblock-btns w-[980px]'>
            {
                tabs.map(tab => (
                    <button 
                     className={`${activeTab === tab && activeTab !== "filter" ? "cta-btn active" : "cta-btn"}`}
                     key={tab} 
                     onClick={() => handleClick(tab)}
                     >
                     <span className='inline-block align-middle'>{tab === "filter" && <FilterIcon/>}</span>
                      {tab}
                    </button>
                ))
            }
        </div>
    </div>
  )
}

export default TabSelector