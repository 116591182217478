import React from 'react';
import RecommendedBookIcon from '../Dashboard/icons/RecommendedBookIcon';
import DownloadBookIcon from '../Dashboard/icons/downloadBookIcon';
import { downLoadPdf } from '../Utils/downLoadPdf';


const BookCard = ({ book }) => {
//   const [isIconVisible, setIconVisible] = useState(false);

  // Toggle the icon's visibility
//   const handleToggleIcon = () => {
//     setIconVisible(!isIconVisible);
//   };
  const maxTextLength = 28;
  const truncateText = text => text.length > maxTextLength ? text.slice(0, maxTextLength) + "..." : text

  return (
        <figure
            key={book?._id} 
            className='book-card relative mb-[1rem] inline-block basis-[175px] h-[220px] lg:h-[309px] lg:basis-[253px] rounded-[12px] bg-[#f2f2f2] group'
          >
            <div className="card-body h-[calc(220px-72px)] lg:h-[calc(309px-81px)] overflow-hidden">
              <div className="img-container rounded-[12px] border border-[#f2f2f2]">
                <img className='h-full object-cover' src={book?.thumbNail} alt="book cover" />
              </div>
            </div>
            <div className="card-footer h-[72px] lg:h-[81px]">
              <div className="flex gap-x-3 items-center">
                <div className="flex-item">
                  <h5 className='text-[13px] lg:text-[14px] font-[500]'>{
                    truncateText(book?.courseTitle)
                    }</h5>
                  <p className='uppercase font-[500] text-[12px] lg:text-[13px] text-[#8e8e8e]'>
                    <span className="icon-container inline-block align-middle">
                      <RecommendedBookIcon />
                    </span>
                     {book?.courseCode}
                  </p>
                </div>
                <div className="flex-item">
                  <span 
                    onClick={() => downLoadPdf(book?.docURL,book?.courseCode)}
                    className={`flex items-center justify-center sm:h-[40px] sm:w-[40px] h-[50px] w-[50px] 
                    translate-y-[30px] p-[2px] lg:p-[8px] rounded-[50%] bg-[#ffe24b] h-[30px] w-[30px] 
                    lg:h-[50px] lg:w-[50px] opacity-0 transition-all duration-300 
                    ease-in-out cursor-pointer group-hover:opacity-100 group-hover:-translate-y-10
                     absolute  bottom-4 right-4 lg:right-6
                    `}
                  >
                    <DownloadBookIcon />
                  </span>
                </div>
              </div>
            </div>
          </figure>
  );
}

export default BookCard;
