import React from 'react'

function PassportGenIcon() {
  return (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M14.4115 3.44002L11.1808 9.03627H20.4538C19.9589 7.68493 19.1657 6.5016 18.074 5.48627C16.9823 4.47077 15.7615 3.78868 14.4115 3.44002ZM3.06825 13.1573C3.11375 13.5163 3.1865 13.9086 3.2865 14.3343H9.69225L5.10375 6.32077C4.41658 7.1746 3.89417 8.0881 3.5365 9.06127C3.17883 10.0343 3 11.0483 3 12.1035C3 12.4472 3.02275 12.7984 3.06825 13.1573ZM5.87125 18.7015C6.99042 19.7298 8.2635 20.4312 9.6905 20.8055L12.9288 15.113H3.527C3.9705 16.4772 4.75192 17.6733 5.87125 18.7015ZM11.1895 21.0535C11.4798 21.0868 11.75 21.1035 12 21.1035C13.2038 21.1035 14.3535 20.874 15.449 20.415C16.5445 19.956 17.5103 19.3105 18.3462 18.4785L15.1905 12.9593L10.4808 20.9535C10.6628 20.9868 10.899 21.0202 11.1895 21.0535ZM14.3077 9.83427L18.8962 17.8863C19.5846 17.0363 20.1073 16.1292 20.4645 15.165C20.8215 14.201 21 13.1805 21 12.1035C21 11.7472 20.974 11.3767 20.922 10.992C20.8702 10.6073 20.8007 10.2214 20.7135 9.83427H14.3077Z" fill="white"/>
<path d="M8.8865 11.363L5.673 5.70927C6.52183 4.87727 7.48758 4.23493 8.57025 3.78227C9.65292 3.32977 10.7962 3.10352 12 3.10352C12.2552 3.10352 12.5468 3.11918 12.875 3.15052C13.2032 3.18202 13.4519 3.21443 13.6212 3.24777L8.8865 11.363Z" fill="white"/>
</svg>

  )
}

export default PassportGenIcon