import React from 'react'

const DepartmentIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M3.28809 14.2506V7.81787L7.03809 4.06787L10.7881 7.81787V14.2506H3.28809ZM4.03809 13.5006H6.46115V11.7698H7.61502V13.5006H10.0381V8.11937L7.03809 5.11937L4.03809 8.11937V13.5006ZM6.46115 9.57756V8.42368H7.61502V9.57756H6.46115ZM11.9997 14.2651V7.31462L8.75315 4.06787H9.8189L12.7497 6.99868V14.2651H11.9997ZM13.9613 14.2651V6.49525L11.5338 4.06787H12.6141L14.7113 6.1795V14.2651H13.9613Z" fill="#1F1F1F"/>
</svg>
  )
}

export default DepartmentIcon