import React from 'react'

function MiniCalculatorIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66667 14.6667H9.33333C12.6667 14.6667 14 13.3333 14 10V6.00001C14 2.66668 12.6667 1.33334 9.33333 1.33334H6.66667C3.33333 1.33334 2 2.66668 2 6.00001V10C2 13.3333 3.33333 14.6667 6.66667 14.6667Z" stroke="#FCFCFC" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 5.05331V5.71998C11 6.26665 10.5533 6.71998 10 6.71998H6C5.45333 6.71998 5 6.27331 5 5.71998V5.05331C5 4.50665 5.44667 4.05331 6 4.05331H10C10.5533 4.05331 11 4.49998 11 5.05331Z" stroke="#FCFCFC" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.42408 9.33333H5.43178" stroke="#FCFCFC" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.9983 9.33333H8.006" stroke="#FCFCFC" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5686 9.33333H10.5763" stroke="#FCFCFC" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.42408 11.6667H5.43178" stroke="#FCFCFC" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.9983 11.6667H8.006" stroke="#FCFCFC" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5686 11.6667H10.5763" stroke="#FCFCFC" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default MiniCalculatorIcon