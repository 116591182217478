import React from 'react'

function ContributorsIcon() {
  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.33203 15.8333H6.94578V9.16667H3.33203V15.8333ZM8.19578 15.8333H11.8016V4.16667H8.19578V15.8333ZM13.0516 15.8333H16.6654V10.8333H13.0516V15.8333ZM2.08203 15.5769V9.42313C2.08203 9.00883 2.22953 8.65417 2.52453 8.35917C2.81953 8.06417 3.17418 7.91667 3.58849 7.91667H6.94578V4.42313C6.94578 4.00882 7.09328 3.65417 7.38828 3.35917C7.68328 3.06417 8.03793 2.91667 8.45224 2.91667H11.5452C11.9595 2.91667 12.3141 3.06417 12.6091 3.35917C12.9041 3.65417 13.0516 4.00882 13.0516 4.42313V9.58334H16.4089C16.8232 9.58334 17.1779 9.73084 17.4729 10.0258C17.7679 10.3208 17.9154 10.6755 17.9154 11.0898V15.5769C17.9154 15.9912 17.7679 16.3458 17.4729 16.6408C17.1779 16.9358 16.8232 17.0833 16.4089 17.0833H3.58849C3.17418 17.0833 2.81953 16.9358 2.52453 16.6408C2.22953 16.3458 2.08203 15.9912 2.08203 15.5769Z" fill="#00140A"/>
</svg>
  )
}

export default ContributorsIcon