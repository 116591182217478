import React, { useState } from 'react'
import { toast, Toaster } from "react-hot-toast";
import PasswordChecklist from "react-password-checklist"
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import EyeIcon from "../icons/EyeIcon";
import LoadingSpinner from "../shared/LoadingSpinner";
import PasswordStrengthCheck from './PasswordStrengthCheck';


const ResetPasswordProtectedRoute  = ({children}) => {
  const isVerifiedUser = localStorage.getItem("isverified_reset_token");

  if(!isVerifiedUser){
    return <Navigate to="/login"/>
  }
  return children;  
}


function ResetPassword() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);

    const [user, setUser] = useState({
      newPassword: "",
      confirmNewPassword: "",
    });
    const email =  searchParams.get("email")
  
      // Handle Password and confirm passswords
      const handlePassword = () => {
        setPassword((prev) => !prev);
      };

      // Handle confirm passswords
      const handleConfirmPassword = () => {
        setConfirmPassword((prev) => !prev);
      };
    
    
      // Handle Change
      const handleChange = (e) => {
        setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      };
      // Handle Submit
      const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const data = {
          newPassword: user.newPassword,
          confirmNewPassword: user.confirmNewPassword,
        };
        const url = `${process.env.REACT_APP_RESET_PASSWORD}?email=${email}`;
        // console.log(url);
        const params = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        try {
          const response = await fetch(url, params);
          const resObj = await response.json();
    
          // if the response was false
          if (!response.ok) {
            throw new Error(resObj.message);
            // setLoading(false);
          }

          toast.success(resObj?.message);
          setLoading(false);
    
          // Login the user after 3 seconds
          setTimeout(() => {
          localStorage.removeItem("isverified_reset_token");
          navigate("/login");
          },3000);
        } catch (err) {
          toast.error(err.message);
          setLoading(false);
        }
      };
    return (
      <ResetPasswordProtectedRoute>
         <section className="auth-section">
         {/* React hot Toast */}
         <Toaster position="top-center" reverseOrder={false}></Toaster>
          <form onSubmit={handleSubmit}>
          <div className="form-container flex">
                <div className="flex-item basis-[100%] md:basis-[72%] lg:basis-[48%] m-auto">
                <Link to="/" className="logo-container">
                  <img src={`assets/1stclassmaterial-logo.svg`} alt="logo" />
                </Link>
                  <h4 className="text-center">reset your password</h4>
                  <div className="password-check- mb-8">
                {/* Password Checker library */}
                {
                !user.newPassword ? null : (
                 <PasswordStrengthCheck passwordVal={user.newPassword}/>
                )
                }
                   </div>
                  <div className="form-field">
                    <label htmlFor="newPassword">type new password</label>
                    <input
                      type={password ? "text": "password"}
                      id="newPassword"
                      name="newPassword"
                      placeholder="********"
                      onChange={handleChange}
                      value={user.newPassword}
                    />
                    <div className="eye-icon-container" onClick={handlePassword}>
                      <EyeIcon />
                    </div>
                  </div>
                  <div className="form-field">
                    <label htmlFor="confirmPassword">confirm new password</label>
                    <input
                      type={confirmPassword ? "text": "password"}
                      id="confirmPassword"
                      name="confirmNewPassword"
                      placeholder="********"
                      onChange={handleChange}
                      value={user.confirmNewPassword}
                    />
                    <div className="eye-icon-container" onClick={handleConfirmPassword}>
                      <EyeIcon />
                    </div>
                  </div>

                  
                  <div className="form-field">
                    <button className="cta-btn" disabled={loading}>
                       {
                        loading ? <LoadingSpinner/> : "save password" 
                       }
                    </button>
                  </div>
      
                  </div>
              </div>
          </form>
      </section>
      </ResetPasswordProtectedRoute>
    )
  }

export default ResetPassword;