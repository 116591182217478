import React from 'react'
import Home from './Home/Home'
import {BrowserRouter as Router, Routes , Route} from "react-router-dom";
import Login from './Auth/Login';
import ActivateAccount from './Auth/ActivateAccount';
import DashboardLayout from './Dashboard/DashboardLayout';
import ProtectedRoute from './Dashboard/ProtectedRoute';
import ForgotPassword from './Auth/ForgotPassword';
import VerifyResetToken from './Auth/VerifyResetToken';
import ResetPassword from './Auth/ResetPassword';

import DashboardHome from './Dashboard/DashboardHome';
import Services from './Dashboard/Sidemenu/Services';
import StudyShare from './Dashboard/Sidemenu/StudyShare';
import Community from './Dashboard/Sidemenu/Community';
import Help from './Dashboard/Sidemenu/Help';
import Feedback from './Dashboard/Sidemenu/Feedback';
import Settings from './Dashboard/Sidemenu/Settings';
import TestingOne from './StrongPassword Testing/TestingOne';
import SettingsIndex from './Dashboard/Settings/SettingsIndex';
import Security from './Dashboard/Settings/Security';
import Notifications from './Dashboard/Settings/Notifications';
import CalculateGp from './Dashboard/Services/CalculateGp';
// import GeneratePassport from './Dashboard/Services/PassportGenerator';
import PassportGenerator from './Dashboard/Services/PassportGenerator';
import GpCalculatorLayout from './Dashboard/Services/GpCalculatorLayout';
import Testing from './FilePond/Testing';
import ViewProfile from './Dashboard/ViewProfile';
// import GpCalculatorLayout1 from './Dashboard/Services/GpCalculatorLayout1';



function Main() {
return (
<Router>
<Routes>
<Route path='/' element={<Home/>}/>
<Route path='/login' element={<Login/>}/>
<Route path='/activate' element={<ActivateAccount/>}/>
<Route path='/forgot-password' element={<ForgotPassword/>}/>
<Route path='/verify-password-token' element={<VerifyResetToken/>}/>
<Route path='/reset-password' element={<ResetPassword/>}/>
<Route path='/testing' element={<TestingOne/>}/>
<Route path='/testing2' element={<Testing/>}/>

{/* Dashbooard Route */}
<Route path='/dashboard' element={<ProtectedRoute><DashboardLayout/></ProtectedRoute>}>
  {/* Nested dashbaord route */}
  <Route index element={<DashboardHome/>}/>
  <Route path='services' element={<Services/>}/>
  <Route path='studyshare' element={<StudyShare/>}>
    {/* Nested Study Share Route */}
  </Route>
  <Route path='community' element={<Community/>}/>
  <Route path='help' element={<Help/>}/>
  <Route path='view-profile' element={<ViewProfile/>}/>
  <Route path='settings' element={<Settings/>}>
      {/* Nested Settings Route */}
    <Route index element={<SettingsIndex/>} />
    <Route path='notifications' element={<Notifications/>} />
    <Route path='security' element={<Security/>} />
  </Route>
  <Route path='feedback' element={<Feedback/>}/>
  <Route path='calculate-gp' element={<CalculateGp/>}/>
  <Route path='gp-calculator' element={<GpCalculatorLayout/>}/>
  <Route path='passport-generator' element={<PassportGenerator/>}/>
</Route>

</Routes>
</Router>
)
}

export default Main