import React from 'react'

const AddMaterialIcon = () => {
  return (
   <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
  <rect width="47" height="47" rx="23.5" fill="white"/>
  <path d="M22.0319 24.9682H12.2402C11.8241 24.9682 11.4753 24.8274 11.194 24.5457C10.9123 24.2641 10.7715 23.9151 10.7715 23.499C10.7715 23.0825 10.9123 22.7338 11.194 22.4528C11.4753 22.1714 11.8241 22.0307 12.2402 22.0307H22.0319V12.2391C22.0319 11.8229 22.1727 11.4742 22.4544 11.1928C22.7361 10.9112 23.085 10.7703 23.5011 10.7703C23.9176 10.7703 24.2664 10.9112 24.5474 11.1928C24.8287 11.4742 24.9694 11.8229 24.9694 12.2391V22.0307H34.7611C35.1772 22.0307 35.526 22.1716 35.8073 22.4533C36.089 22.7349 36.2298 23.0838 36.2298 23.5C36.2298 23.9165 36.089 24.2652 35.8073 24.5462C35.526 24.8276 35.1772 24.9682 34.7611 24.9682H24.9694V34.7599C24.9694 35.1761 24.8286 35.5248 24.5469 35.8061C24.2652 36.0878 23.9163 36.2287 23.5002 36.2287C23.0837 36.2287 22.7349 36.0878 22.4539 35.8061C22.1726 35.5248 22.0319 35.1761 22.0319 34.7599V24.9682Z" fill="black"/>
  </svg>
  )
}

export default AddMaterialIcon