import React, { useState } from 'react'
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import EyeIcon from "../icons/EyeIcon";
import LoadingSpinner from "../shared/LoadingSpinner";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

    // Handle Password and confirm passswords
    const handlePassword = () => {
      setPassword((prev) => !prev);
    };
  
  
    // Handle Change
    const handleChange = (e) => {
      setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    // Handle Submit
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      const data = {
        email: user.email,
        password: user.password,
      };
      const url = process.env.REACT_APP_LOGIN;
      // console.log(url);
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      try {
        const response = await fetch(url, params);
        const resObj = await response.json();
  
        // if the response was false
        if (!response.ok) {
          throw new Error(resObj.message);
          // setLoading(false);
        }
        // console.log(resObj?.data);
        localStorage.setItem("isAuthenticated_1stclassmaterial_user",true);
        localStorage.setItem("login_token",resObj?.data?.accessToken);
        toast.success(resObj?.message);
        setLoading(false);
  
        // Login the user after 3 seconds
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    };
  return (
    <section className="auth-section">
       {/* React hot Toast */}
       <Toaster position="top-center" reverseOrder={false}></Toaster>
        <form onSubmit={handleSubmit}>
        <div className="form-container flex-container-2">
              <div className="flex-item">
              <Link to="/" className="logo-container">
                <img src={`assets/1stclassmaterial-logo.svg`} alt="logo" />
              </Link>
                <h4 className="text-center">sign in your account</h4>
             
                <div className="form-field">
                  <label htmlFor="email">e -mail</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="email@gmail.com"
                    onChange={handleChange}
                    value={user.email}
                  />
                </div>

                <div className="form-field">
                  <label htmlFor="password">password</label>
                  <input
                    type={password ? "text": "password"}
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={user.password}
                  />
                  <div className="eye-icon-container" onClick={handlePassword}>
                    <EyeIcon />
                  </div>
                </div>
              

              <div className="form-field remember flex items-center justify-between">
                <label htmlFor="remember" style={{marginBottom:'0'}}>
                  <input type="checkbox" id='remember'  /> 
                   {" "} remember me
                </label>
                <Link className="text-[#2c7e54] font-[500]" to="/forgot-password">
                   forgot passsword
                  </Link>
              </div>
                
                <div className="form-field">
                  <button className="cta-btn" disabled={loading}>
                     {
                      loading ? <LoadingSpinner/> : "sign in" 
                     }
                  </button>
                </div>
    
                <div className="flex items-center justify-center">
                  <p className="text-center font-[600]">Don't have an account? </p>
                  <Link to="/" className="text-[#2c7e54] font-[600]">sign up</Link>
                </div>
                </div>
                <div className="flex-item right-side hidden xl:block">
              <div className="img-container">
                <img
                  src={`/assets/auth-rightside-img.svg`}
                  alt="women holding books"
                />
              </div>
              </div>
            </div>
        </form>
    </section>
  )
}

export default Login