import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

function Settings() {
  return (
    <div className='settings-container mt-20'>
      <p>Profile / Settings</p>
      <h5>settings</h5>

      <div className="flex flex-col lg:flex-row gap-[4%]">
        <div className="flex-item mb-8 lg:mb-0 lg:basis-[22%]">
          <div className="nav-links">
            <NavLink to="/dashboard/settings" end>personal information</NavLink>
            <NavLink to="notifications">notification</NavLink>
            <NavLink to="security">security</NavLink>
          </div>
        </div>
        <div className="flex-item lg:basis-[72%]">
          <Outlet/>
        </div>
      </div>
    </div>
  )
}

export default Settings