import React from 'react'
import HelpIcon from '../icons/HelpIcon'
import HelpIndex from '../Help/HelpIndex'

function Help() {
  return (
    <div className='mt-20'>
      <div className="icon-container mb-[40px]">
        <HelpIcon/>
      </div>
      <h4>Help</h4>
      <HelpIndex/>
    </div>
  )
}

export default Help