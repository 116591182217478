import React from 'react'

function StudyShareActiveIcon() {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.57728 20C4.34845 20 4.15661 19.9226 4.00178 19.7678C3.84695 19.6129 3.76953 19.4211 3.76953 19.1923V10.8155C3.76953 10.5782 3.84695 10.3829 4.00178 10.2298C4.15661 10.0766 4.34845 10 4.57728 10H7.11578C7.34461 10 7.53637 10.0774 7.69103 10.2322C7.84586 10.3871 7.92328 10.5789 7.92328 10.8078V19.1845C7.92328 19.4218 7.84586 19.6171 7.69103 19.7703C7.53637 19.9234 7.34461 20 7.11578 20H4.57728ZM10.5448 20C10.3088 20 10.1141 19.9226 9.96078 19.7678C9.80762 19.6129 9.73103 19.4211 9.73103 19.1923V4.80775C9.73103 4.57892 9.80845 4.38708 9.96328 4.23225C10.1181 4.07742 10.3099 4 10.5388 4H13.4558C13.6918 4 13.8864 4.07742 14.0398 4.23225C14.1929 4.38708 14.2695 4.57892 14.2695 4.80775V19.1923C14.2695 19.4211 14.1921 19.6129 14.0373 19.7678C13.8824 19.9226 13.6906 20 13.4618 20H10.5448ZM16.8848 20C16.6559 20 16.4642 19.9233 16.3095 19.77C16.1547 19.6167 16.0773 19.4267 16.0773 19.2V12.7998C16.0773 12.5729 16.1547 12.3829 16.3095 12.2298C16.4642 12.0766 16.6559 12 16.8848 12H19.4233C19.6521 12 19.8439 12.0767 19.9988 12.23C20.1536 12.3833 20.231 12.5733 20.231 12.8V19.2003C20.231 19.4271 20.1536 19.6171 19.9988 19.7703C19.8439 19.9234 19.6521 20 19.4233 20H16.8848Z" fill="white"/>
</svg>
  )
}

export default StudyShareActiveIcon