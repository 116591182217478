import React from 'react'
import { Navigate } from 'react-router-dom';

// ProtectedRoute component that checks if the user is authenticated
function ProtectedRoute({children}) {

  const isAuthenticated =  localStorage.getItem("isAuthenticated_1stclassmaterial_user");

   // Redirect to the login page if the user is not authenticated 
  if(!isAuthenticated) {
  return <Navigate to="/login" />;
  }
  return children;
}

export default ProtectedRoute;