import React, { useEffect, useState } from 'react';
import { Toaster,toast } from 'react-hot-toast';
import StudyShareIcon from '../icons/StudyShareIcon';
import StudyShareIndex from '../StudyShare/StudyShareIndex';
import TabSelector from '../../TabSelector/TabSelector';
import TextBooks from '../StudyShare/TextBooks';
import PastQuestions from '../StudyShare/PastQuestions';
import LectureNotes from '../StudyShare/LectureNotes';
import Fillers from '../StudyShare/Fillers';
import FilterPage from './FilterPage';
import FilterIcon from '../icons/FilterIcon';
import Summaries from '../StudyShare/Summaries';
import FilterBookModal from '../../Modals/FilterBookModal';
import CloseModalIcon from '../icons/CloseModalIcon';

function StudyShare() {
  const [activeTab, setActiveTab] = useState("all");
  const[contributesNo,setContributesNo] =  useState(0);
  const[books,setBooks] = useState([]);
  const[fetchingStatus,setFetchingStatus] =  useState(false);
  const[textbooks,setTextBooks]  = useState([])

  useEffect(()=>{
    /**
   * THIS CODE TEMPORARY FETCHES THE BOOKS UPLOADED BY THE USER
   */
   const fetchBooks = async() => {
    const url = process.env.REACT_APP_BOOKS;
    setFetchingStatus(true)
    try{
     const res = await fetch(url,{headers:{"Authorization":`Bearer ${localStorage.getItem("login_token")}`}});
     const resObj = await res.json();
      setBooks(resObj?.data?.books)
    }
    catch(err){
     toast.error(`Error gettings books: ${err?.message}`)
    } 
    finally{
    setFetchingStatus(false)
    }
  }

   const { noOfContributions } = JSON.parse(localStorage.getItem("userData"));
   setContributesNo(noOfContributions);
   fetchBooks()
  },[])



  // Default tabs without filter
  const baseTabs = ["all", "text books", "past questions", "lecture notes", "fillers","summaries"];
  
  // Include the "filter" tab only when "text books" is the active tab
  const tabs = activeTab === "text books" || activeTab === "filter"  ? [...baseTabs, "filter"] : baseTabs;
  
  // Handle click for each Tab
  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseFilter = () => {
    setActiveTab("text books")
  }


  // MAP the books arrays and do some Sorting according to Textbooks, fillers e.t.c
    const sortBooks = type => {
       if(type === "all"){
        return books;
       }
       return books.filter(book => book.courseType === type)
    }





  return (
    <div className='studyshare-container mt-20'>
      <h1 className='icon-container'>
        <StudyShareIcon />
      </h1>
      <h5>studyshare</h5>

      {/* Render TabSelector with conditionally included tabs */}
      <TabSelector
        tabs={tabs}
        handleClick={handleClick}
        activeTab={activeTab}
      />

      <div className='display-activetab-details'>
        {/* Show component based on the active tab */}
        {activeTab === "all" && 
        <StudyShareIndex
         contributesNo = {contributesNo}
         books = {sortBooks("all")}
         fetchingStatus = {fetchingStatus}
         />}

        {activeTab === "text books" && 
        <TextBooks 
        books = {sortBooks("text books")}
        fetchingStatus = {fetchingStatus}
        />}

        {activeTab === "past questions" && 
        <PastQuestions
        books = {sortBooks("past questions")}
        fetchingStatus = {fetchingStatus}
         />}

        {activeTab === "lecture notes" && 
        <LectureNotes 
        books = {sortBooks("lecture notes")}
        fetchingStatus = {fetchingStatus}
        />}

        {activeTab === "fillers" && 
        <Fillers  
        books = {sortBooks("fillers")}
        fetchingStatus = {fetchingStatus}
        /> }
        
        {activeTab === "filter" && 
        <FilterBookModal 
        title={"Filter by"}
        closeModalIcon = <CloseModalIcon/>
        closeModal = {handleCloseFilter}
        />}
        
        {activeTab === "summaries" && 
        <Summaries 
        books = {sortBooks("summaries")}
        fetchingStatus = {fetchingStatus}
        />}
      </div>
    </div>
  );
}

export default StudyShare;
