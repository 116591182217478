import React from 'react'

function MenuBarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M4.25 18.1345C4.0375 18.1345 3.85942 18.0626 3.71575 17.9187C3.57192 17.7751 3.5 17.5969 3.5 17.3842C3.5 17.1717 3.57192 16.9937 3.71575 16.85C3.85942 16.7065 4.0375 16.6347 4.25 16.6347H19.75C19.9625 16.6347 20.1406 16.7066 20.2843 16.8502C20.4281 16.9941 20.5 17.1723 20.5 17.385C20.5 17.5975 20.4281 17.7756 20.2843 17.9192C20.1406 18.0627 19.9625 18.1345 19.75 18.1345H4.25ZM4.25 13.25C4.0375 13.25 3.85942 13.1781 3.71575 13.0342C3.57192 12.8904 3.5 12.7122 3.5 12.4997C3.5 12.2871 3.57192 12.109 3.71575 11.9655C3.85942 11.8218 4.0375 11.75 4.25 11.75H19.75C19.9625 11.75 20.1406 11.8219 20.2843 11.9657C20.4281 12.1096 20.5 12.2877 20.5 12.5002C20.5 12.7129 20.4281 12.891 20.2843 13.0345C20.1406 13.1782 19.9625 13.25 19.75 13.25H4.25ZM4.25 8.36524C4.0375 8.36524 3.85942 8.29341 3.71575 8.14974C3.57192 8.00591 3.5 7.82766 3.5 7.61499C3.5 7.40249 3.57192 7.22441 3.71575 7.08074C3.85942 6.93724 4.0375 6.86549 4.25 6.86549H19.75C19.9625 6.86549 20.1406 6.93741 20.2843 7.08124C20.4281 7.22491 20.5 7.40308 20.5 7.61574C20.5 7.82824 20.4281 8.00633 20.2843 8.14999C20.1406 8.29349 19.9625 8.36524 19.75 8.36524H4.25Z" fill="#00140A"/>
  </svg>
  )
}

export default MenuBarIcon