import React, { useState, useEffect } from "react";
import { Toaster,toast } from "react-hot-toast";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import axios from "axios"; // Assuming axios is used for API requests
import PlusIcon from "../icons/PlusIcon";
import LoadingSpinner from "../../shared/LoadingSpinner";
import DownloadIcon from "../icons/DownloadIcon";
import MiniCalculatorIcon from "../icons/MiniCalculatorIcon";


function GpCalculatorLayout() {
  const[loading,setLoading] = useState(false)
  const [resultsData, setResultsData] = useState([]);
  const[addRowloading,setAddRowLoading] = useState(false)
  const[deleteRowId,setDeleteRowId] = useState(null);
  const[updateRowId,setUpdateRowId] = useState(null);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const[cgpa,setCGPA] = useState(null);

  const fetchUserResults = async () => {
    try {
      const url = process.env.REACT_APP_USER_RESULT;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
        },
      });
      const resObj = await res.json();

      // if the response failed
      if(!res.ok){
        throw new Error(resObj.message)
      }
      setResultsData(resObj?.data?.results || []);
      // toast.success(resObj?.message)
    } catch (err) {
      toast.error(err?.message)
    }
  };

  useEffect(()=>{
  fetchUserResults();
  },[])


  // Handle Calculate CGPA 
  const handleCaculateCGPA = async() => {
   try{
    setLoading(true)
   const url =  `${process.env.REACT_APP_USER_RESULT}/calculate-gp`;
   const res  = await fetch(url,{headers:{"Authorization":`Bearer ${localStorage.getItem("login_token")}`}});
   const resObj = await res.json();

   if(!res.ok){
    throw new Error(resObj.message);
   }

   setCGPA(resObj?.data?.cgpa)
   toast.success(resObj.message);
   toast.success(resObj?.data?.cgpa);
  //  console.log(resObj?.data?.cgpa);
   }
   catch(err){
    toast.error(`Error calculating CGPA ${err?.message}`)
   }
   finally{
    setLoading(false)
   }
  }



  /**
   * AUTO TABLE and JsPDF Converter
   */
  //Handle PDF Generation
  const downloadPDF = () => {
    const doc = new jsPDF();
    // Define table headers and rows
    const tableColumn = [["Course Code", "Course Title", "Unit Load", "Grade", "Grade Point", "Semester"]];
    const tableRows = resultsData.map((item) => [
      item.courseCode,
      item.courseTitle,
      item.unitLoad,
      item.grade,
      item.gradePoint,
      item.semester,
    ]);

    const tFoot = [ [cgpa]];


     // Add title to the PDF
     doc.text("1st Class Material Result Sheet", 10, 15);

     // Add autoTable for table data
     doc.autoTable({
       head: tableColumn,
       body: tableRows,
       foot:tFoot,
       startY: 20,
       headStyles: { fillColor: [44, 126, 84] }, // Custom background color for header
      //  footStyles: { fillColor: [233, 30, 99] },
     });

     // Save the generated PDF
    doc.save("1st_class_material_results.pdf");
  }
   


  // Handle inline editing
  const handleEdit = (id, field) => {
    const updatedData = resultsData.map((item) => {
      if (item?._id === id) {
        return { ...item, editingField: field };
      }
      return item;
    });
    setResultsData(updatedData);
  };

 

  const handleBlur = (id, field, e) => {
    const value = e.target.innerText;

    const updatedData = resultsData.map((item) => {
      if (item?._id === id) {
        return { ...item, [field]: value, editingField: null };
      }
      return item;
    });

    setResultsData(updatedData);

    //Call updateRow immediately when the user finishes typing
     updateRow(id, { ...updatedData.find(item => item?._id === id), [field]: value });

       //Refresh the fetchUsersResult
       fetchUserResults();
  };


  // const handleInputChange = (id,field,e) => {
  //   const value = e.target.innerText;
  //  // Clear the previous timeout if it exists
  //  if (debounceTimeout) {
  //   clearTimeout(debounceTimeout);
  // }

  // // Set a new timeout to update the row after a delay
  //   const timeout = setTimeout(() => {
  //     updateRow(id, { ...resultsData.find(item => item?._id === id), [field]: value });
  //   }, 500); // Delay in milliseconds

  //   setDebounceTimeout(timeout);

  // }

  // const handleKeyUp = (id,field,e) => {
  //   const value = e.target.innerText;
  //  // Clear the previous timeout if it exists
  //  if (debounceTimeout) {
  //   clearTimeout(debounceTimeout);
  // }

  // // Set a new timeout to update the row after a delay
  //   const timeout = setTimeout(() => {
  //     updateRow(id, { ...resultsData.find(item => item?._id === id), [field]: value });
  //   }, 500); // Delay in milliseconds

  //   setDebounceTimeout(timeout);

  // } 




  /**
   * RESULT DATA CRUD OPERATION
   */
  // Handle Row adding
  const addRow = async() => {
    try{
      setAddRowLoading(true)
      const url = process.env.REACT_APP_USER_RESULT;
      const params = {
        method:"POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
        }
      }
      const res = await fetch(url,params)
      // const { data } = res.data;
      const resObj = await res.json();
      console.log(resObj)
      toast.success(resObj?.message)
      fetchUserResults();
    }
    catch(err){
      toast.error(err.message);
    }
    finally{
      setAddRowLoading(false)
    }
  };


   //Handle saving updated data to MongoDB
   const updateRow = async (id,updatedData) => {
    // console.log(id)
    // const updatedItem =  resultsData.find(item =>  item?._id === id);
    // console.log(updatedItem)
    // const dataToUpdate = {...updatedItem}
    try {
      setUpdateRowId(id);
      const url = `${process.env.REACT_APP_USER_RESULT}/${id}`;
      const params = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
        },
        body:JSON.stringify(updatedData)
      };
      const res = await fetch(url,params);
     
      const resObj = await res.json();

      if(!res.ok){
        throw new Error(resObj.message)
      }
      toast.success(resObj?.message);

      //Refresh the fetchUsersResult
      fetchUserResults();
      
    } catch (err) {
    toast.error(`Error editing data : ${err?.message}`)
    }
    finally{
      setUpdateRowId(null)
    }
  };
  //  const updateRow = async (id) => {
  //   console.log(id)
  //   const updatedItem =  resultsData.find(item =>  item?._id === id);
  //   console.log(updatedItem)
  //   const dataToUpdate = {...updatedItem}
  //   try {
  //     setUpdateRowId(id);
  //     const url = `${process.env.REACT_APP_USER_RESULT}/${id}`;
  //     const params = {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("login_token")}`,
  //       },
  //       body:JSON.stringify(dataToUpdate)
  //     };
  //     const res = await fetch(url,params);
     
  //     const resObj = await res.json();

  //     if(!res.ok){
  //       throw new Error(resObj.message)
  //     }
  //     toast.success(resObj?.message);

  //     //Refresh the fetchUsersResult
  //     fetchUserResults();
      
  //   } catch (err) {
  //   toast.error(`Error updating row : ${err?.message}`)
  //   }
  //   finally{
  //     setUpdateRowId(null)
  //   }
  // };
 
  //Hanlde deleting Row and refresh data
  const deleteRow = async (id) => {
  try {
    // set loading to true
    setDeleteRowId(id)
    const url = `${process.env.REACT_APP_USER_RESULT}/${id}`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      },
    };

    const res = await fetch(url, params);

    // Check if response is not OK
    if (!res.ok) {
      throw new Error("Network Error!");
    }

    toast.success("Row deleted successfully");
    // Refresh the fetch users Result
    fetchUserResults();
  } catch (err) {
    toast.error(`Error deleting row: ${err?.message} `);
  } finally {
    setDeleteRowId(null)
  }
};


console.log(resultsData)


  return (
    <div
      className="grade-point-container table-container mt-20"
      id="table-container"
    >
      {/* React hot Toast */}
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      {/* <button className='cta-btn' onClick={addRow}>Add Row</button> */}
      <div className="flex gap-2 mb-8 top">
       <div className="flex-wrapper basis-[100%] md:basis-[72%] m-auto">
       <div className="btn-container">
                    <button className="download-btn" id="download-btn" onClick={downloadPDF}> 
                    <span className="icon inline-block align-middle mr-1"><DownloadIcon/> </span>
                     Download</button>
                </div>
                <div className="btn-container">
                    <button disabled={loading} className="gp-calc-btn" onClick={handleCaculateCGPA}> <span className="icon inline-block align-middle mr-1"><MiniCalculatorIcon/></span>
                     {loading ? <LoadingSpinner/> : "Get CGPA"}
                    </button>
                </div>
       </div>
       </div>
      <table className="table-element">
        <thead>
          <tr>
            <th>S/N</th>
            <th>Course code</th>
            <th>Course title</th>
            <th>unit load</th>
            <th>grade</th>
            <th>grade point</th>
            <th>semester</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {resultsData.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td> {/* Display sequential S/N */}
              <td
                contentEditable={item.editingField === "courseCode"}
                suppressContentEditableWarning={true}
                onClick={() => handleEdit(item?._id, "courseCode")}
                // onKeyUp={(e) => handleKeyUp(item.id, "courseCode", e)}
                onBlur={(e) => handleBlur(item?._id, "courseCode", e)}
                className={item.editingField === "courseCode" ? "active" : ""}
              >{item.courseCode}</td>
              <td
                contentEditable={item.editingField === "courseTitle"}
                suppressContentEditableWarning={true}
                onClick={() => handleEdit(item?._id, "courseTitle")}
                // onKeyUp={(e) => handleKeyUp(item.id, "courseTitle", e)}
                onBlur={(e) => handleBlur(item?._id, "courseTitle", e)}
                className={item.editingField === "courseTitle" ? "active" : ""}
              >{item.courseTitle}</td>
              <td
                contentEditable={item.editingField === "unitLoad"}
                suppressContentEditableWarning={true}
                onClick={() => handleEdit(item?._id, "unitLoad")}
                // onKeyUp={(e) => handleKeyUp(item.id, "unitLoad", e)}
                onBlur={(e) => handleBlur(item?._id, "unitLoad", e)}
                className={item.editingField === "unitLoad" ? "active" : ""}
              >{item.unitLoad}</td>
              <td
                contentEditable={item.editingField === "grade"}
                suppressContentEditableWarning={true}
                onClick={() => handleEdit(item?._id, "grade")}
                // onKeyUp={(e) => handleKeyUp(item.id, "grade", e)}
                onBlur={(e) => handleBlur(item?._id, "grade", e)}
                className={item.editingField === "grade" ? "active" : ""}
              >{item.grade}</td>
              <td
                contentEditable={item.editingField === "gradePoint"}
                suppressContentEditableWarning={true}
                onClick={() => handleEdit(item?._id, "gradePoint")}
                // onKeyUp={(e) => handleKeyUp(item.id, "gradePoint", e)}
                onBlur={(e) => handleBlur(item?._id, "gradePoint", e)}
                className={item.editingField === "gradePoint" ? "active" : ""}
              >{item.gradePoint}</td>
              <td
                contentEditable={item.editingField === "semester"}
                suppressContentEditableWarning={true}
                onClick={() => handleEdit(item?._id, "semester")}
                // onKeyUp={(e) => handleKeyUp(item.id, "semester", e)}
                onBlur={(e) => handleBlur(item?._id, "semester", e)}
                className={item.editingField === "semester" ? "active" : ""}
              >{item.semester}</td>
              <td>
                {/* <button className="cta-btn mr-2" onClick={() => updateRow(item?._id)}>
                  {
                    updateRowId === item?._id ? <LoadingSpinner/> : "Save"
                  }
                </button> */}
                <button disabled={deleteRowId===item?._id} className="cta-btn" onClick={() => deleteRow(item?._id)}>
                  {deleteRowId === item?._id ? <LoadingSpinner/> : "Delete"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        {cgpa && (
         <tr>
          <td>{cgpa}</td>
         </tr>
        )}
        </tfoot>
      </table>

    
      <div className="flex-container bottom">
        <div className="add-row btn-container">
          <button className="btn" onClick={addRow}>
            <span className="plus-icon inline-block mr-2">
              <PlusIcon />
            </span>
            {addRowloading ? <LoadingSpinner/> : "Add row"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default GpCalculatorLayout;
