import React, { useState } from 'react'
import ModalWrapper from './ModalWrapper'
import LevelIcon from '../Dashboard/icons/LevelIcon'
import DepartmentIcon from '../Dashboard/icons/DepartmentIcon'

const FilterBookModal = ({title,closeModal,closeModalIcon}) => {


  return (
    <ModalWrapper>
        <div className='filter-container overflow-y-auto lg:overflow-y-hidden webkit-scrollbar h-[484px] rounded-[12px] bg-white p-[28px] w-[95%] lg:w-[989px]'>
        <div className="modal-header flex m-[24px] justify-between items-start">
        <h4>{title}</h4>
        <span 
         className='icon-container p-[0.1rem] cursor-pointer h-[27px] w-[27px] border border-[#a5a5a5] rounded-[50%]'  
         onClick={closeModal}>
         {closeModalIcon}
        </span>
        </div>

        <div className="lg:flex gap-x-4">
          <div className="flex-item basis-[172px] flex flex-row lg:flex-col">
            <div className="btn-container cursor-pointer rounded-[12px] bg-[#f2f2f2] mb-1">
              <button className='cta-btn items-center gap-x-[14px]'>
               <span className='w-[30px] rounded-[8px] bg-white p-[6px]'>
                <LevelIcon/>
                </span>
                <span>Level</span>
              </button>
            </div>
            <div className="btn-container rounded-[12px]">
              <button className='cta-btn items-center gap-x-[14px]'>
               <span className='w-[30px] rounded-[8px] p-[6px]'>
                <DepartmentIcon/>
                </span>
                <span>Department</span>
              </button>
            </div>
            {/* <div className="inner-flex-item"></div> */}
          </div>
          <div className="flex-item">
            <p className='text-[#8e8e8e]'>Level</p>

          </div>
        </div>

    </div>
    </ModalWrapper>
  )
}

export default FilterBookModal