import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import LoadingSpinner from "../shared/LoadingSpinner";
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import axios from "axios";

GlobalWorkerOptions.workerSrc = pdfjsWorker;

function Testing() {
  const [material, setMaterial] = useState({
    courseTitle: "",
    courseType: "",
    courseCode: "",
    department: "",
    level: "",
  });

  // State to hold a single file object
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [thumbNail, setThumbNail] = useState(null);

  // Cloudinary file upload
  const cloudName = process.env.REACT_APP_CLOUD_NAME;
  const cloudinaryUploadPreset = process.env.REACT_APP_EBOOKS_UPLOAD_PRESET;

  const uploadToCloudinary = async (file, resourceType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", cloudinaryUploadPreset);

    try {
      let apiKey = `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`;
      const response = await axios.post(apiKey, formData);
      const { secure_url } = response?.data;
      return secure_url;
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Handle Change for form inputs
  const handleChange = (e) => {
    setMaterial((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // This function handles the file input change event
  const handleFileChange = async (event) => {
    // Get the selected file from the file input element
    const selectedFile = event.target.files[0];

    // Store the selected file in state (e.g., for later use or upload)
    setFile(selectedFile);

    // Check if the selected file exists and is a PDF (MIME type 'application/pdf')
    if (selectedFile && selectedFile.type === "application/pdf") {
      // If the file is a PDF, create a FileReader instance to read the file as an array buffer
      const reader = new FileReader();

      // Define the onload event for the FileReader, which triggers after reading completes
      reader.onload = async (event) => {
        // Convert the file data to a Uint8Array for PDF.js to process
        const pdfData = new Uint8Array(event.target.result);

        // Load the PDF using pdfjs-dist's getDocument function
        const pdf = await getDocument(pdfData).promise;

        // Get the first page of the PDF (as a preview)
        const page = await pdf.getPage(1);

        // Define the viewport (dimensions) for rendering the page
        const viewport = page.getViewport({ scale: 1 });

        // Create a temporary canvas element to render the PDF page
        const canvas = document.createElement("canvas");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Get the 2D rendering context for drawing on the canvas
        const context = canvas.getContext("2d");

        // Create a render context with the canvas and viewport
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        // Render the page on the canvas, which returns a promise when rendering completes
        await page.render(renderContext).promise;

        // Convert the canvas drawing to a data URL and store it as the PDF's thumbnail
        setThumbNail(canvas.toDataURL());
      };

      // Start reading the file as an ArrayBuffer to access its raw binary data
      reader.readAsArrayBuffer(selectedFile);
    } else {
      // If the selected file is not a PDF, set a placeholder or generic preview image
      setThumbNail("path/to/generic-placeholder.png");
    }
  };

  // Handle Submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(material)
    // Simple input validation
    if (
      !material.courseTitle ||
      !material.courseType ||
      !material.courseCode ||
      !material.department ||
      !material.level ||
      !file ||
      !thumbNail 
    ) {
      toast.error(
        "Please provide the necessary details to upload document..!",
      );
      return;
    }
    setLoading(true);

    const imgUrl = await uploadToCloudinary(thumbNail, "image");
    const pdfUrl = await uploadToCloudinary(file, "raw");

    // console.log(imgUrl);
    // console.log(pdfUrl);
    setLoading(false);

    const url = process.env.REACT_APP_BOOKS;

    const data = {
      courseTitle: material?.courseTitle,
      courseType: material?.courseType,
      courseCode: material?.courseCode,
      department: material?.department,
      level: material?.level,
      docURL: pdfUrl,
      thumbNail: imgUrl,
    };

    const params = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("login_token")}`,
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      if (!response.ok) {
        throw new Error(resObj.message);
      }
      toast.success(resObj?.message);
    } catch (err) {
      toast.error(err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-10">
      <form onSubmit={handleSubmit}>
        <Toaster position="top-center" reverseOrder={false} />

        {/* Form Fields */}
        <div className="flex-item">
          <div className="form-field">
            <label htmlFor="courseTitle">Title</label>
            <input
              type="text"
              id="courseTitle"
              name="courseTitle"
              placeholder="Add a Title"
              value={material.courseTitle}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <label htmlFor="courseType">Type</label>
            <div className="select-field">
              <select name="courseType" id="courseType" onChange={handleChange}>
                <option value="">Please select a type</option>
                <option value="text books">Text Books</option>
                <option value="lecture notes">Lecture Notes</option>
                <option value="fillers">Fillers</option>
                <option value="past questions">Past Questions</option>
              </select>
            </div>
          </div>

          <div className="form-field">
            <label htmlFor="courseCode">Course Code</label>
            <input
              type="text"
              name="courseCode"
              id="courseCode"
              value={material.courseCode}
              onChange={handleChange}
              placeholder="Ex: GSP 101"
            />
          </div>

          <div className="form-field">
            <label htmlFor="department">Department</label>
            <input
              type="text"
              name="department"
              id="department"
              value={material.department}
              onChange={handleChange}
              placeholder="Ex: Electronic Engineering"
            />
          </div>

          <div className="form-field">
            <label htmlFor="level">Level</label>
            <div className="select-field">
              <select name="level" id="level" onChange={handleChange}>
                <option value="">Please select your level</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="600">600</option>
                <option value="700">700</option>
              </select>
            </div>
          </div>

          {/* File Input Field */}
          <div className="form-field">
            <label htmlFor="docURL">Upload Document</label>
            <input
              type="file"
              id="docURL"
              name="docURL"
              accept=".pdf, .doc, .docx, .ppt, .pptx" // Accept other file types as needed
              onChange={handleFileChange}
            />
            {thumbNail && <img src={thumbNail} alt="PDF Preview" />}
          </div>

          <div className="form-field footer-btns">
            <button className="cta-btn" disabled={loading}>
              {loading ? <LoadingSpinner /> : "Proceed"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Testing;
