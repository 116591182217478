import React, { useState } from 'react'
import "./testing.css"

function TestingOne() {
// Define state variables for password, message and progress
const [password ,setPassword] = useState("")
const [message ,setMessage] = useState("")
const [progress ,setProgress] = useState("");

// function to get the color for the progress
function getActiveColor(type) {
 let activeType = type === "Strong" ? "#3fbb68" : type === "Medium" ? "#fe8040" : "#ff0054"
 return activeType;

//  if(type === "Strong") return "#3fbb68" ;
//  if(type === "Medium") return "#fe8040";
//  return "#ff0054";
}

const handlePassword = (passwordValue) => {
// create an object to track password strength checks
const strengthChecks = {
    length:0,
    hasUpperCase:false,
    hasLowerCase:false,
    hasDigits:false,
    hasSpecialChar:false
}

// update the strength checks based on the password value
strengthChecks.length = passwordValue.length >= 8 ? true : false;
strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
strengthChecks.hasDigits = /[0-9]+/.test(passwordValue);
strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

// Create a list of verified checks
let verifiedLists = Object.values(strengthChecks).filter(val => val);

//Determine the strength of the passsword based on  verified checks
let strength = verifiedLists.length === 5 ?
"Strong"
: verifiedLists.length >= 2 
? "Medium" 
: "Weak"

// Update the password, progress and message state variables
setPassword(passwordValue)
setProgress(`${(verifiedLists.length / 5) * 100}% `);
setMessage(strength)
}

const[hidePasssword,setHidePassword] = useState(true)
  return (
    <section>
        <div className='password-testing'>
        <h4>passswor strength checker</h4>
        <div className="form-container">
            <form>
                <div className="form-field">
                    <input 
                     type={hidePasssword ? "password" : "text"}
                     value={password}
                     onChange={({target}) => {handlePassword(target.value)}}
                     className='input'
                     placeholder='Enter a password'
                    />
                </div>

            {/* Progressbar that changes color based on password change */}
            <div className="progress-bg">
                <div className="progress"
                style={{
                    width:progress,
                    backgroundColor:getActiveColor(message)
                }}
                >

                </div>
                {/* <div className="flex-wrapper">
                    <div className="progress" style={{width:progress,backgroundColor:getActiveColor(message)}}></div>
                    <div className="progress" style={{width:progress,backgroundColor:getActiveColor(message)}}></div>
                    <div className="progress" style={{width:progress,backgroundColor:getActiveColor(message)}}></div>
                    <div className="progress" style={{width:progress,backgroundColor:getActiveColor(message)}}></div>
                    <div className="progress" style={{width:progress,backgroundColor:getActiveColor(message)}}></div>
                </div> */}
            </div>

            {
                password.length !== 0 ? (
                 <p className="message" style={{color:getActiveColor(message)}}>
                    Your password is {message}
                 </p>
                ) : null
                }
            </form>
        </div>
    </div>
    </section>
  )
}

export default TestingOne