import React from 'react'

function ArrowUpIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.625 9.46719L18.625 15.4672L17.225 16.8672L12.625 12.2672L8.025 16.8672L6.625 15.4672L12.625 9.46719Z" fill="#00140A"/>
    </svg>
    
  )
}

export default ArrowUpIcon