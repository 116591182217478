import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import HomeIcon from "../icons/HomeIcon";
import ServicesIcon from "../icons/ServicesIcon";
import HomeIconActive from "../icons/HomeIconActive";
import FeedbackIcon from "../icons/FeedbackIcon";
import HelpIcon from "../icons/HelpIcon";
import SettingsIcon from "../icons/SettingsIcon";
import CommunityIcon from "../icons/CommunityIcon";
import StudyShareIcon from "../icons/StudyShareIcon";
import StudyShareActiveIcon from "../icons/StudyShareActiveIcon";
import ServicesActiveIcon from "../icons/ServicesActiveIcon";
import SettingsActiveIcon from "../icons/SettingsActiveIcon";
import GradePointCalcIcon from "../icons/GradePointCalcIcon";
import PassportGenIcon from "../icons/PassportGenIcon";
import FeedBackActiveIcon from "../icons/FeedBackActiveIcon";
import HelpActiveIcon from "../icons/HelpActiveIcon";
import CommunityActiveIcon from "../icons/CommunityActiveIcon";

// className={({ isActive }) => (window.location.pathname === '/dashboard' ? 'active' : '')}
// className={({ isActive }) => isActive ? 'active' : ''}

function DashboardSideMenu({ userData }) {
  const[active,setIsActive] = useState(false);
  const handleHover = () => {
    setIsActive(prev => !prev)
  }
  return (
    <aside className="aside mt-16">
        <div className="side-menu">
      <nav className="nav-container">
        <ul className="list-menu">
          <li>
            <NavLink
              to="/dashboard"
              end
            //   className={({ isActive }) => (isActive ? "active" : "")}
            >
              {({ isActive }) => (
                <div className="flex flex-col items-center gap-2">
                  <span className="nav-icon">
                    {isActive ? <HomeIconActive /> : <HomeIcon />}
                  </span>
                  <span>home</span>
                </div>
              )}
            </NavLink>
          </li>
          <li className="relative services" onClick={handleHover}>
              <div className={`${active ? "bg-[var(--green-color)] text-white rounded-[8px]" : ""} flex flex-col items-center gap-2 p-[0.35rem]`}>
                <span className="nav-icon">
                  {active ? <ServicesActiveIcon/> : <ServicesIcon />}
                </span>
                <span className="text-[0.625rem]">Services</span>
              </div>
              <ul
                    className={`pl-8 ${active ? "dropdown-menu active" : "dropdown-menu"} absolute`}
                  >
                    <div className={`${active ? "dropdown active" :"dropdown"}`}>
                      <h5>our services</h5>
                       <p className="text-[#090909] mb-[27px]">Let’s help you get things done right</p>
                      <li className="dropdown-list">
                        <NavLink to="calculate-gp">
                          <div className="flex flex-row items-center gap-2">
                            <span className="nav-icon">
                              <GradePointCalcIcon />
                            </span>
                            <span>GP calculator</span>
                          </div>
                        </NavLink>
                      </li>
                      <li className="dropdown-list">
                        <NavLink to="passport-generator">
                          <div className="flex flex-row items-center gap-2">
                            <span className="nav-icon">
                              <PassportGenIcon />
                            </span>
                            <span>passport generator</span>
                          </div>
                        </NavLink>
                      </li>
                    </div>
                  </ul>
          </li>
          <li>
            <NavLink to="studyshare">
              {
               ({isActive}) => (
               <div className="flex flex-col items-center gap-2">
                <span className="nav-icon">
                  {isActive ? <StudyShareActiveIcon/> : <StudyShareIcon />}
                </span>
                <span>studyshare</span>
              </div>
               )
              }
            </NavLink>
          </li>
          <li>
            <NavLink to="community">
              {
                ({isActive}) => (
                <div className="flex flex-col items-center gap-2">
                <span className="nav-icon">
                  {isActive ? <CommunityActiveIcon/> : <CommunityIcon />}
                </span>
                <span>community</span>
              </div>
                )
              }
            </NavLink>
          </li>
          <li>
            <NavLink to="settings">
              {
                ({isActive}) => (
                  <div className="flex flex-col items-center gap-2">
                  <span className="nav-icon">
                    {isActive ? <SettingsActiveIcon/> : <SettingsIcon />}
                  </span>
                  <span>Settings</span>
                </div>
                )
              }
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="help">
              {
                ({isActive}) => (
                <div className="flex flex-col items-center gap-2">
                <span className="nav-icon">
                  {isActive ? <HelpActiveIcon/> : <HelpIcon />}
                </span>
                <span>help</span>
              </div>
                )
              }
            </NavLink>
          </li> */}
          <li>
            <NavLink to="feedback">
            {
                ({isActive}) => (
                  <div className="flex flex-col items-center gap-2">
                  <span className="nav-icon">
                    {isActive ? <FeedBackActiveIcon/> : <FeedbackIcon />}
                  </span>
                  <span>feedback</span>
                </div>
                )
              }
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
    </aside>
  );
}

export default DashboardSideMenu;
