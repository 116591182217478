import React from 'react'

function DownloadIcon() {
  return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 7.33334V11.3333L7.33333 10" stroke="#535353" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.0013 11.3333L4.66797 10" stroke="#535353" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.6654 6.66668V10C14.6654 13.3333 13.332 14.6667 9.9987 14.6667H5.9987C2.66536 14.6667 1.33203 13.3333 1.33203 10V6.00001C1.33203 2.66668 2.66536 1.33334 5.9987 1.33334H9.33203" stroke="#535353" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.6654 6.66668H11.9987C9.9987 6.66668 9.33203 6.00001 9.33203 4.00001V1.33334L14.6654 6.66668Z" stroke="#535353" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default DownloadIcon