import React from 'react'

function GradePointCalcIcon() {
  return (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.30775 17.7958H9.19225V15.7958H11.1923V14.9113H9.19225V12.9113H8.30775V14.9113H6.30775V15.7958H8.30775V17.7958ZM13.3077 17.0458H17.6923V16.1613H13.3077V17.0458ZM13.3077 14.5458H17.6923V13.6613H13.3077V14.5458ZM14.1 10.592L15.5 9.19202L16.9 10.592L17.527 9.96502L16.127 8.55352L17.527 7.15352L16.9 6.52652L15.5 7.92652L14.1 6.52652L13.473 7.15352L14.873 8.55352L13.473 9.96502L14.1 10.592ZM6.55775 8.99577H10.9423V8.11127H6.55775V8.99577ZM5.6155 20.1035C5.15517 20.1035 4.77083 19.9493 4.4625 19.641C4.15417 19.3327 4 18.9483 4 18.488V5.71902C4 5.25868 4.15417 4.87435 4.4625 4.56602C4.77083 4.25768 5.15517 4.10352 5.6155 4.10352H18.3845C18.8448 4.10352 19.2292 4.25768 19.5375 4.56602C19.8458 4.87435 20 5.25868 20 5.71902V18.488C20 18.9483 19.8458 19.3327 19.5375 19.641C19.2292 19.9493 18.8448 20.1035 18.3845 20.1035H5.6155Z" fill="white"/>
</svg>
  )
}

export default GradePointCalcIcon