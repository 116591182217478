import React from 'react'
import { Link } from 'react-router-dom'
import TransparentCalcIcon from '../icons/TransparentCalcIcon'
import CalculatorIcon from '../icons/CalculatorIcon'

function CalculateGp() {
  return (
    <div className='mt-20'>
       <div className="flex flex-col lg:flex-row items-center">
        <div className="flex-item text-center mb-20 lg:mb-0 basis-[62%] mr-auto">
        <h4 className='lowercase'>stay up to date on your <span className='uppercase'>GP</span></h4>
        <p>let's help you say updated</p>
        <div className="img-container">
            <img src={`/assets/gp-calculator-banner.svg`} alt="gp caculator banner" />
        </div>
        </div>
        <div className="flex-item text-center basis-[38%]">
         <span className="icon-container inline-block">
            <CalculatorIcon/>
         </span>
            <div className="calculate-gp-container">
                <Link to="/dashboard/gp-calculator" className='cta-btn rounded-[8px] mb-4'>calculate GP <span className='inline-block align-middle border border-[var(--white-color)] ml-3 rounded-[4px]'><TransparentCalcIcon/></span></Link>
            </div>
            <h5 className='lowercase'>calculate your <span className='uppercase'>GP</span> </h5>
            <p>Get accurate result after calculating</p>
        </div>
       </div>
    </div>
  )
}

export default CalculateGp