import React from 'react'
import BookCard from '../../shared/BookCard'
import LoadingSpinner from '../../shared/LoadingSpinner'

function TextBooks({
  fetchingStatus,
  books
}) {
  return (
    <div>
      <div className="flex flex-wrap gap-[8px] lg:gap-[34px]">
         {
          fetchingStatus ? <LoadingSpinner stroke="var(--green-color)"/> : (
            books.map(book => (
              <BookCard book={book} />
          ))
          )
         }
       </div>
    </div>
  )
}

export default TextBooks