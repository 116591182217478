import React from 'react'

function CalculatorIcon() {
  return (
<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.71054 27.7628L9.23257 6.96714C9.38993 6.24349 9.78221 5.68009 10.4094 5.27693C11.0366 4.87378 11.7121 4.75088 12.4357 4.90824L33.2314 9.43027C33.955 9.58763 34.5184 9.97991 34.9216 10.6071C35.3247 11.2343 35.4476 11.9098 35.2903 12.6334L30.7683 33.4291C30.6109 34.1527 30.2186 34.7161 29.5914 35.1193C28.9642 35.5224 28.2888 35.6453 27.5651 35.488L6.76944 30.966C6.04579 30.8086 5.48239 30.4163 5.07924 29.7891C4.67608 29.1619 4.55318 28.4865 4.71054 27.7628ZM9.60814 13.0837L32.4086 18.0417L33.6617 12.2793C33.7252 11.9869 33.6836 11.7263 33.5366 11.4973C33.3894 11.2686 33.1696 11.1224 32.8772 11.0589L12.0816 6.53684C11.7892 6.47328 11.5286 6.51495 11.2996 6.66188C11.0709 6.80914 10.9247 7.02894 10.8612 7.32128L9.60814 13.0837ZM16.0044 22.8717L22.5253 24.2897L23.9147 17.9003L17.3938 16.4823L16.0044 22.8717ZM14.261 30.8894L20.7819 32.3074L22.1712 25.9183L15.6502 24.5003L14.261 30.8894ZM7.86461 21.1017L14.3758 22.5176L15.7652 16.1282L9.254 14.7123L7.86461 21.1017ZM24.1539 24.6439L30.6651 26.0597L32.0545 19.6703L25.5433 18.2544L24.1539 24.6439ZM7.12358 29.3373L12.6323 30.5352L14.0216 24.1462L7.51047 22.7303L6.33915 28.117C6.27558 28.4093 6.31726 28.6699 6.46419 28.8989C6.61145 29.1276 6.83124 29.2738 7.12358 29.3373ZM22.4105 32.6615L27.9193 33.8594C28.2116 33.9229 28.4722 33.8813 28.7012 33.7343C28.9299 33.5871 29.0761 33.3673 29.1396 33.0749L30.311 27.6883L23.7998 26.2725L22.4105 32.6615Z" fill="#808080"/>
</svg>
  )
}

export default CalculatorIcon