import React from 'react'

const SilverBadge = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="29" height="27" viewBox="0 0 29 27" fill="none">
  <path d="M5.36494 10.6306L10.8907 10.0323C10.9729 10.0234 11.0476 9.98004 11.0961 9.91302L13.128 7.10685L14.9463 4.59562C15.0767 4.4155 15.3524 4.44098 15.4476 4.6419L18.2587 10.5746C18.2941 10.6494 18.3596 10.7056 18.4388 10.7295L23.7615 12.3298C23.9841 12.3967 24.0435 12.684 23.8657 12.8337L19.0796 16.8643C19.0055 16.9267 18.9671 17.0218 18.977 17.1181L19.5464 22.639C19.5713 22.8815 19.3061 23.0464 19.0997 22.9167L13.9392 19.6736C13.8603 19.6241 13.7625 19.615 13.6759 19.6493L8.00868 21.8923C7.78196 21.982 7.55143 21.7713 7.62044 21.5375L9.19139 16.2142C9.2188 16.1213 9.19847 16.0209 9.13707 15.946L5.17019 11.1069C5.02283 10.9271 5.13385 10.6556 5.36494 10.6306Z" fill="#C2C2C2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6981 9.46574L14.4721 4.25363C14.8633 3.71333 15.6904 3.78973 15.976 4.39253L18.7314 10.2077L23.9296 11.7706C24.5974 11.9714 24.7757 12.8332 24.2423 13.2824L19.5746 17.2133L20.128 22.5802C20.2031 23.3077 19.4073 23.8025 18.788 23.4133L19.0993 22.9179C19.3058 23.0476 19.571 22.8827 19.546 22.6402L18.9767 17.1193C18.9668 17.0229 19.0052 16.9279 19.0792 16.8655L23.8654 12.8349C24.0432 12.6852 23.9838 12.3979 23.7612 12.3309L18.4385 10.7306C18.3592 10.7068 18.2938 10.6505 18.2583 10.5757L15.4473 4.64307C15.3521 4.44215 15.0764 4.41667 14.946 4.59676L11.0958 9.91415C11.0473 9.98117 10.9726 10.0245 10.8903 10.0334L5.3646 10.6317C5.13352 10.6568 5.0225 10.9283 5.16985 11.108L9.13673 15.9471C9.19814 16.022 9.21847 16.1225 9.19106 16.2154L7.62011 21.5386C7.5511 21.7725 7.78162 21.9832 8.00835 21.8934L8.22366 22.4375C7.54352 22.7066 6.85191 22.0745 7.05894 21.373L8.58607 16.1983L4.71737 11.479C4.27527 10.9397 4.60834 10.1251 5.30161 10.05L10.6981 9.46574ZM13.753 20.2491L18.788 23.4133L19.0993 22.9179L13.9389 19.6748C13.86 19.6252 13.7622 19.6162 13.6755 19.6505L8.00835 21.8934L8.22366 22.4375L13.753 20.2491Z" fill="#646464"/>
  <path d="M8.555 11.8118L11.6739 11.4741C11.7561 11.4652 11.8308 11.4219 11.8793 11.3548L13.0918 9.68031L14.0907 8.30069C14.2212 8.1206 14.4969 8.14605 14.5921 8.347L16.2067 11.7546C16.2421 11.8293 16.3076 11.8856 16.3868 11.9094L19.3911 12.8127C19.6137 12.8796 19.673 13.1669 19.4953 13.3166L16.7762 15.6065C16.7021 15.6689 16.6637 15.7639 16.6736 15.8603L16.9916 18.9433C17.0166 19.1859 16.7513 19.3508 16.5449 19.2211L13.6365 17.3933C13.5576 17.3437 13.4598 17.3347 13.3732 17.369L10.1792 18.6331C9.9525 18.7228 9.72197 18.5121 9.79098 18.2783L10.6682 15.3056C10.6957 15.2127 10.6753 15.1122 10.6139 15.0373L8.36025 12.2881C8.2129 12.1084 8.32392 11.8368 8.555 11.8118Z" fill="#E9E7E7"/>
  <path d="M9.86518 12.1066L11.9409 11.8819C12.0232 11.873 12.0978 11.8297 12.1463 11.7626L13.0037 10.5786L13.6476 9.68936C13.7779 9.50927 14.0537 9.53472 14.1489 9.73567L15.2449 12.0488C15.2803 12.1236 15.3458 12.1799 15.425 12.2037L17.4245 12.8049C17.6471 12.8718 17.7065 13.1591 17.5287 13.3088L15.7054 14.8442C15.6314 14.9066 15.593 15.0017 15.6029 15.098L15.8119 17.1245C15.8369 17.3671 15.5717 17.532 15.3652 17.4022L13.4329 16.1879C13.354 16.1383 13.2562 16.1293 13.1696 16.1636L11.0475 17.0034C10.8208 17.0932 10.5903 16.8825 10.6593 16.6486L11.2359 14.6946C11.2633 14.6018 11.243 14.5013 11.1816 14.4264L9.67043 12.583C9.52308 12.4032 9.6341 12.1317 9.86518 12.1066Z" fill="#C2C2C2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.89844 20.5951L7.62034 21.5375C7.55133 21.7713 7.78186 21.982 8.00858 21.8923L13.6758 19.6493C13.7624 19.615 13.8602 19.6241 13.9391 19.6736L19.0996 22.9167C19.306 23.0464 19.5712 22.8815 19.5462 22.639L18.9769 17.1181C18.967 17.0218 19.0054 16.9267 19.0795 16.8643L23.8656 12.8337C24.0434 12.684 23.984 12.3967 23.7614 12.3298L22.6488 11.9953L17.9093 15.9867C17.8352 16.0491 17.7968 16.1441 17.8067 16.2405L18.376 21.7614C18.4011 22.0039 18.1358 22.1688 17.9294 22.0391L12.7689 18.796C12.69 18.7464 12.5922 18.7374 12.5056 18.7717L7.89844 20.5951ZM18.0237 10.0789L15.4475 4.6419C15.3523 4.44098 15.0766 4.4155 14.9462 4.59562L14.78 4.82516L17.0884 9.69694C17.1238 9.77172 17.1893 9.828 17.2685 9.85182L18.0237 10.0789Z" fill="#7E7E7E"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0476 8.33002C13.1768 8.42694 13.2031 8.61031 13.1061 8.73959L11.3508 11.08C11.3011 11.1462 11.2257 11.1883 11.1433 11.1958L9.53425 11.3421C9.37335 11.3567 9.23106 11.2381 9.21643 11.0772C9.2018 10.9163 9.32037 10.774 9.4813 10.7594L10.9608 10.6248L12.638 8.38853C12.735 8.25925 12.9183 8.23307 13.0476 8.33002Z" fill="#FFF9D8"/>
  <path d="M18.138 19.6793C18.138 19.8408 18.007 19.9718 17.8454 19.9718C17.6838 19.9718 17.5529 19.8408 17.5529 19.6793C17.5529 19.5177 17.6838 19.3867 17.8454 19.3867C18.007 19.3867 18.138 19.5177 18.138 19.6793Z" fill="#5D5D5D"/>
  <path d="M18.4304 18.5093C18.4304 18.6709 18.2995 18.8019 18.1379 18.8019C17.9763 18.8019 17.8453 18.6709 17.8453 18.5093C17.8453 18.3478 17.9763 18.2168 18.1379 18.2168C18.2995 18.2168 18.4304 18.3478 18.4304 18.5093Z" fill="#5D5D5D"/>
  <path d="M18.723 16.1695C18.723 16.3311 18.5921 16.462 18.4305 16.462C18.2689 16.462 18.1379 16.3311 18.1379 16.1695C18.1379 16.0079 18.2689 15.877 18.4305 15.877C18.5921 15.877 18.723 16.0079 18.723 16.1695Z" fill="#5D5D5D"/>
  <path d="M17.3609 16.0461C17.3609 16.1395 17.2851 16.2152 17.1917 16.2152C17.0983 16.2152 17.0226 16.1395 17.0226 16.0461C17.0226 15.9527 17.0983 15.877 17.1917 15.877C17.2851 15.877 17.3609 15.9527 17.3609 16.0461Z" fill="#5D5D5D"/>
  <path d="M18.723 15.0773C18.723 15.1707 18.6473 15.2465 18.5539 15.2465C18.4605 15.2465 18.3848 15.1707 18.3848 15.0773C18.3848 14.9839 18.4605 14.9082 18.5539 14.9082C18.6473 14.9082 18.723 14.9839 18.723 15.0773Z" fill="#5D5D5D"/>
  <path d="M18.0465 17.1245C18.0465 17.2785 17.9216 17.4034 17.7676 17.4034C17.6136 17.4034 17.4888 17.2785 17.4888 17.1245C17.4888 16.9705 17.6136 16.8457 17.7676 16.8457C17.9216 16.8457 18.0465 16.9705 18.0465 17.1245Z" fill="#5D5D5D"/>
  <path d="M21.0908 13.6047C21.0908 13.6981 21.0151 13.7738 20.9217 13.7738C20.8283 13.7738 20.7526 13.6981 20.7526 13.6047C20.7526 13.5113 20.8283 13.4355 20.9217 13.4355C21.0151 13.4355 21.0908 13.5113 21.0908 13.6047Z" fill="#5D5D5D"/>
  <path d="M22.4072 13.55C22.4072 13.6434 22.3315 13.7191 22.2381 13.7191C22.1447 13.7191 22.069 13.6434 22.069 13.55C22.069 13.4566 22.1447 13.3809 22.2381 13.3809C22.3315 13.3809 22.4072 13.4566 22.4072 13.55Z" fill="#5D5D5D"/>
  <path d="M21.2005 14.392C21.2005 14.5157 21.1003 14.6159 20.9765 14.6159C20.8528 14.6159 20.7526 14.5157 20.7526 14.392C20.7526 14.2683 20.8528 14.168 20.9765 14.168C21.1003 14.168 21.2005 14.2683 21.2005 14.392Z" fill="#5D5D5D"/>
  <path d="M18.7687 17.632C18.7687 17.7254 18.693 17.8011 18.5995 17.8011C18.5061 17.8011 18.4304 17.7254 18.4304 17.632C18.4304 17.5386 18.5061 17.4629 18.5995 17.4629C18.693 17.4629 18.7687 17.5386 18.7687 17.632Z" fill="#5D5D5D"/>
  <path d="M17.306 18.1711C17.306 18.2645 17.2303 18.3402 17.1369 18.3402C17.0435 18.3402 16.9678 18.2645 16.9678 18.1711C16.9678 18.0777 17.0435 18.002 17.1369 18.002C17.2303 18.002 17.306 18.0777 17.306 18.1711Z" fill="#5D5D5D"/>
  <path d="M18.9973 20.6125C18.9973 20.7059 18.9216 20.7816 18.8282 20.7816C18.7348 20.7816 18.6591 20.7059 18.6591 20.6125C18.6591 20.5191 18.7348 20.4434 18.8282 20.4434C18.9216 20.4434 18.9973 20.5191 18.9973 20.6125Z" fill="#5D5D5D"/>
  <path d="M17.0226 17.1931C17.0226 17.385 16.867 17.5405 16.6752 17.5405C16.4833 17.5405 16.3278 17.385 16.3278 17.1931C16.3278 17.0013 16.4833 16.8457 16.6752 16.8457C16.867 16.8457 17.0226 17.0013 17.0226 17.1931Z" fill="#5D5D5D"/>
  <path d="M20.1857 15.1459C20.1857 15.3882 19.9893 15.5847 19.7469 15.5847C19.5046 15.5847 19.3081 15.3882 19.3081 15.1459C19.3081 14.9035 19.5046 14.707 19.7469 14.707C19.9893 14.707 20.1857 14.9035 20.1857 15.1459Z" fill="#5D5D5D"/>
</svg>
  )
}

export default SilverBadge