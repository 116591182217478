import React from 'react'

function CloseModalIcon() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M9.99977 11.3781L5.77227 15.6058C5.65685 15.7211 5.51178 15.7801 5.33706 15.7829C5.16247 15.7856 5.01477 15.7265 4.89393 15.6058C4.77324 15.485 4.71289 15.3386 4.71289 15.1667C4.71289 14.9947 4.77324 14.8483 4.89393 14.7275L9.12164 10.5L4.89393 6.27251C4.77865 6.15709 4.71963 6.01202 4.71685 5.8373C4.71421 5.66272 4.77324 5.51501 4.89393 5.39418C5.01477 5.27348 5.16115 5.21313 5.3331 5.21313C5.50504 5.21313 5.65143 5.27348 5.77227 5.39418L9.99977 9.62188L14.2273 5.39418C14.3427 5.2789 14.4878 5.21987 14.6625 5.21709C14.8371 5.21445 14.9848 5.27348 15.1056 5.39418C15.2263 5.51501 15.2866 5.6614 15.2866 5.83334C15.2866 6.00529 15.2263 6.15168 15.1056 6.27251L10.8779 10.5L15.1056 14.7275C15.2209 14.8429 15.2799 14.988 15.2827 15.1627C15.2853 15.3373 15.2263 15.485 15.1056 15.6058C14.9848 15.7265 14.8384 15.7869 14.6664 15.7869C14.4945 15.7869 14.3481 15.7265 14.2273 15.6058L9.99977 11.3781Z" fill="black"/>
</svg>
  )
}

export default CloseModalIcon