import React, { useEffect, useState } from 'react'
// import {toast,Toaster} from "react-hot-toast";
import { Link, useSearchParams } from 'react-router-dom'

function ActivateAccount() {
    const[searchParams] = useSearchParams();
    const[loading,setLoading] = useState(false);
    const[status,setStatus] =  useState("")

    // GET the email and token from url
    const email = searchParams.get("email");
    const token = searchParams.get("token");

useEffect(() => {
    const confirmActivation = async () => {
     setLoading(true)
        try{
            const url = `${process.env.REACT_APP_ACTIVATE_USER}?email=${email}&token=${token}`;
            const res = await fetch(url);
            const resObj = await res.json();
            setStatus(resObj?.status)
            setLoading(false);
            
            if(!res.ok){
            throw new Error(resObj.message);
         }
        }
        catch(err){
        // toast.error(err.message);
        setLoading(false)
        }
      };
  confirmActivation();
  },[email,token]);

  if(loading){
    return(
    <section className='activate-account'>
        <div className='flex flex-col flex-wrap justify-center items-center'>
            <p>Activating user account...</p>
        </div>
    </section>
    )
  }
  
  return ( 
    <section className="activate-account">
       {/* React hot Toast */}
       {/* <Toaster position="top-center" reverseOrder={false}></Toaster> */}
       {
        status === "success" ? (
            <div className="modal-container">
            <figure className="success-reg-card">
              <div className="img-container">
                <img src={`/assets/activate-icon.png`} alt="valid activate account" />
              </div>
              <div className="contents">
                <h4 style={{ color: "#2c7e54" }}>Activated Successfully</h4>
                <p>
                  Your account {email.replace(/^"(.*)"$/, "($1)")} has been
                  successfully activated <br />
                  Please kindly click on <Link className='text-[#2c7e54] inline-block' to="/login">Login</Link> to
                  continue with your dashboard
                </p>
              </div>
            </figure>
          </div>
        )
        :
        (
        <div className="modal-container">
          <figure className="success-reg-card">
          <div className="img-container">
                <img src={`/assets/invalid-activation-icon.png`} alt="invalid activate account" />
              </div>
            <div className="contents">
              <h4 style={{ color: "red" }}>Invalid Activation Token !</h4>
              <p>
                Your account {email.replace(/^"(.*)"$/, "($1)")} has been
                already activated <br />
                Please kindly click on <Link className='text-[#2c7e54] inline-block' to="/login">Login</Link> to
                continue with your dashboard
              </p>
            </div>
          </figure>
        </div>
        )
       }

        
    </section>
  )
}

export default ActivateAccount