import React from 'react'

function Community() {
  return (
    <div className='mt-20'>
        <h4>No Available Community !</h4>
    </div>
  )
}

export default Community