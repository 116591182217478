import React from 'react'

function HomeIcon() {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 19H9.69225V13.923C9.69225 13.6942 9.76967 13.5023 9.9245 13.3475C10.0793 13.1928 10.2712 13.1155 10.5 13.1155H13.5C13.7288 13.1155 13.9207 13.1928 14.0755 13.3475C14.2303 13.5023 14.3077 13.6942 14.3077 13.923V19H18V10.3077C18 10.2051 17.9776 10.1121 17.9327 10.0287C17.8879 9.94541 17.827 9.87174 17.75 9.80774L12.3655 5.74999C12.2628 5.66033 12.141 5.61549 12 5.61549C11.859 5.61549 11.7372 5.66033 11.6345 5.74999L6.25 9.80774C6.173 9.87174 6.11208 9.94541 6.06725 10.0287C6.02242 10.1121 6 10.2051 6 10.3077V19ZM5 19V10.3077C5 10.0519 5.05725 9.80958 5.17175 9.58074C5.28608 9.35191 5.44425 9.16349 5.64625 9.01549L11.0308 4.93849C11.3126 4.72316 11.6347 4.61549 11.997 4.61549C12.3593 4.61549 12.6834 4.72316 12.9693 4.93849L18.3538 9.01549C18.5558 9.16349 18.7139 9.35191 18.8282 9.58074C18.9427 9.80958 19 10.0519 19 10.3077V19C19 19.268 18.9003 19.5017 18.701 19.701C18.5017 19.9003 18.268 20 18 20H14.1155C13.8865 20 13.6947 19.9226 13.54 19.7677C13.3852 19.6129 13.3077 19.4211 13.3077 19.1922V14.1155H10.6923V19.1922C10.6923 19.4211 10.6148 19.6129 10.46 19.7677C10.3053 19.9226 10.1135 20 9.8845 20H6C5.732 20 5.49833 19.9003 5.299 19.701C5.09967 19.5017 5 19.268 5 19Z" fill="#161616"/>
</svg>

  )
}

export default HomeIcon