import React from 'react'

const flexPositions = {
 centered:"justify-center",
 left:"justify-start",
 right:"justify-end"
}

function ModalWrapper(props) {
    const{ modalPosition ="centered", children } = props;
    const style = `${flexPositions[modalPosition]} backdrop-blur-sm backdrop-brightness-75 fixed overflow-y-auto top-0 left-0 w-full h-full z-[1040] flex items-center animate-fadeIn`

  return (
    <div style={{background:`rgba(0, 0, 0, 0.25)`}} className={style}>{children}</div>
  )
}

export default ModalWrapper