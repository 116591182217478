import React, { useState } from 'react'
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../shared/LoadingSpinner";


function ForgotPassword() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
      email: ""
    });

  // Handle Change
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // Handle Submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      email: user.email,
    };
    const url = process.env.REACT_APP_RESET_PASSWORD_TOKEN;
    // console.log(url);
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization":`Bearer ${localStorage.getItem("login_token")}`
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }

    //   localStorage.setItem("reset_password_token",resObj?.data?.authToken)
      toast.success(resObj?.message);
      setLoading(false);

      //move to the next route
      setTimeout(() => {
        navigate(`/verify-password-token?authToken=${resObj?.data?.authToken}&email=${user.email}`);
      }, 3000);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  }; 




  return (
    <section className="auth-section">
       {/* React hot Toast */}
       <Toaster position="top-center" reverseOrder={false}></Toaster>
        <form onSubmit={handleSubmit}>
        <div className="form-container flex">
              <div className="flex-item basis-[100%] md:basis-[72%] lg:basis-[48%] m-auto">
              <Link to="/" className="logo-container">
                <img src={`assets/1stclassmaterial-logo.svg`} alt="logo" />
              </Link>
                <h4 className="text-center">confirm your email</h4>
             
                <div className="form-field">
                  <label htmlFor="email">e -mail</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="email@gmail.com"
                    onChange={handleChange}
                    value={user.email}
                  />
                </div>
               
                
                <div className="form-field">
                  <button className="cta-btn" disabled={loading}>
                     {
                      loading ? <LoadingSpinner/> : "confirm" 
                     }
                  </button>
                </div>
    
                </div>
                
            </div>
        </form>
    </section>
  )
}

export default ForgotPassword;