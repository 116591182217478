import React from "react";

function HelpIndex() {
  return (
    <div className="lg:flex mb-8">
      <div className="flex-item basis-[48%]">
        <h5>frequently asked questions</h5>
      </div>
      <div className="flex-item basis-[48%]">
        <h6 className="font-[600]">Need to get in touch</h6>
        <p>
          Have questions? Reach out by filling out the inquiry form or sending
          us an email at sovereignrockeventcenter@gmail.com or our mobile phone
          210-714-5932
        </p>
      </div>
    </div>
  );
}

export default HelpIndex;
