import React from 'react'

function HomeIconActive() {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 19V10.3077C5 10.0519 5.05725 9.80958 5.17175 9.58074C5.28608 9.35191 5.44425 9.16349 5.64625 9.01549L11.0308 4.93849C11.3126 4.72316 11.6347 4.61549 11.997 4.61549C12.3593 4.61549 12.6834 4.72316 12.9693 4.93849L18.3538 9.01549C18.5558 9.16349 18.7139 9.35191 18.8282 9.58074C18.9427 9.80958 19 10.0519 19 10.3077V19C19 19.268 18.9003 19.5017 18.701 19.701C18.5017 19.9003 18.268 20 18 20H14.6155C14.3865 20 14.1947 19.9226 14.04 19.7677C13.8852 19.6129 13.8077 19.4211 13.8077 19.1922V14.423C13.8077 14.1942 13.7303 14.0023 13.5755 13.8475C13.4207 13.6928 13.2288 13.6155 13 13.6155H11C10.7712 13.6155 10.5793 13.6928 10.4245 13.8475C10.2697 14.0023 10.1923 14.1942 10.1923 14.423V19.1922C10.1923 19.4211 10.1148 19.6129 9.96 19.7677C9.80533 19.9226 9.6135 20 9.3845 20H6C5.732 20 5.49833 19.9003 5.299 19.701C5.09967 19.5017 5 19.268 5 19Z" fill="white"/>
</svg>

  )
}

export default HomeIconActive