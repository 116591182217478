import React from 'react'

function TransparentCalcIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.33663 8.07049H10.9134V6.74374H4.33663V8.07049Z" fill="white"/>
    <path d="M15.65 10.4649L17.75 8.36487L19.85 10.4649L20.7905 9.52437L18.6905 7.40712L20.7905 5.30712L19.85 4.36662L17.75 6.46662L15.65 4.36662L14.7095 5.30712L16.8095 7.40712L14.7095 9.52437L15.65 10.4649Z" fill="white"/>
    <path d="M14.4616 16.3955H21.0384V15.0687H14.4616V16.3955Z" fill="white"/>
    <path d="M6.96163 21.2705H8.28838V18.2705H11.2884V16.9437H8.28838V13.9437H6.96163V16.9437H3.96163V18.2705H6.96163V21.2705Z" fill="white"/>
    <path d="M14.4616 20.1455H21.0384V18.8187H14.4616V20.1455Z" fill="white"/>
    </svg>
  )
}

export default TransparentCalcIcon