import React, { useState } from "react";
// import PasswordChecklist from "react-password-checklist"
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import EyeIcon from "../icons/EyeIcon";
import LoadingSpinner from "../shared/LoadingSpinner";
import LeftArrowIcon from "../icons/LeftArrowIcon";
import PasswordStrengthCheck from "./PasswordStrengthCheck";


function Register() {
  const navigate = useNavigate();
  const [nextPage, setNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [user, setUser] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    department: "",
    level: "",
    agree:""
  });




  // Hanle next page
  const handleNextPage = () => {
    setNextPage((prev) => !prev);
  };

  // Handle Password and confirm passswords
  const handlePassword = () => {
    setPassword((prev) => !prev);
  };
  const handleConfirmPassword = () => {
    setConfirmPassword((prev) => !prev);
  };

  // Handle Change
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Handle Submit
  const handleSubmit = async (event) => {
    event.preventDefault();
      //check if the user agreed to the terms
     if(!user.agree){
      toast.error("Please agree to the terms and conditions to continue")
       return;
     }
    setLoading(true);
    const data = {
      fullName: user.fullName,
      email: user.email,
      password: user.password,
      confirmPassword: user.confirmPassword,
      phoneNumber: user.phoneNumber,
      department: user.department,
      level: user.level,
    };

    const url = process.env.REACT_APP_REGISTER;
    // console.log(url);
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }
      // console.log(resObj?.data);
      toast.success(resObj?.message);
      setLoading(false);

      // Login the user after 3 seconds
      setTimeout(() => {
        navigate("/login");
      },6000);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <section className="auth-section">
      {/* React hot Toast */}
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <form onSubmit={handleSubmit}>
        {nextPage && (
          <>
            <div
              className="arrow-left absolute top-[55px] lg:top-[85px] left-[25px] cursor-pointer"
              onClick={handleNextPage}
            >
              <LeftArrowIcon />
            </div>
            <div className="form-container flex-container-2">
              {/* Back Arrow */}
              <div className="flex-item">
                <Link to="/" className="logo-container">
                  <img src={`assets/1stclassmaterial-logo.svg`} alt="logo" />
                </Link>
                <h4 className="text-center">
                  Almost there, Please Provide the following information
                </h4>
                <div className="form-field">
                  <label htmlFor="phoneNumber">phone number</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="09017912839"
                    maxLength="11"
                    value={user.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="department">department</label>
                  <input
                    type="text"
                    name="department"
                    id="department"
                    value={user.department}
                    onChange={handleChange}
                    placeholder="Ex: Electronic engineering"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="level">level</label>
                  <div className="select-field">
                    <select name="level" id="level" onChange={handleChange}>
                      <option value="">Please select your level</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="300">300</option>
                      <option value="400">400</option>
                      <option value="500">500</option>
                      <option value="600">600</option>
                      <option value="700">700</option>
                    </select>
                  </div>
                </div>

                <div className="form-field">
                  <button className="cta-btn" disabled={loading}>
                    {loading ? <LoadingSpinner /> : "proceed"}
                  </button>
                </div>
              </div>
              <div className="flex-item right-side hidden xl:block">
                <div className="img-container">
                  <img
                    src={`/assets/auth-rightside-img2.svg`}
                    alt="women holding books"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {!nextPage && (
          <div className="form-container flex-container-2">
            <div className="flex-item">
              <Link to="/" className="logo-container">
                <img src={`assets/1stclassmaterial-logo.svg`} alt="logo" />
              </Link>
              <h4 className="text-center">create an account</h4>
              <div className="form-field">
                <label htmlFor="fullName">full name</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="fullname"
                  onChange={handleChange}
                  value={user.fullName}
                />
              </div>
              <div className="form-field">
                <label htmlFor="email">e -mail</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="email@gmail.com"
                  onChange={handleChange}
                  value={user.email}
                />
              </div>
             
              <div className="form-field">
                <label htmlFor="password">password</label>
                <input
                  type={password ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={user.password}
                />
                <div className="eye-icon-container" onClick={handlePassword}>
                  <EyeIcon />
                </div>
              </div>

              <div className="password-check mb-8">
                {/* Password Checker library */}
                {
                !user.password ? null : (
                //   <PasswordChecklist
                //   rules={[
                //     "minLength",
                //     "specialChar",
                //     "number",
                //     "capital",
                //     "match",
                //     "lowercase"
                //   ]}
                //   minLength={8}
                //   value={user.password}
                //   valueAgain={user.confirmPassword}
                //   iconSize={12}
                // />
                <PasswordStrengthCheck passwordVal={user.password}/>
                )
                }
              </div>
              
              <div className="form-field">
                <label htmlFor="confirmPassword">confirm Password</label>
                <input
                  type={confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  value={user.confirmPassword}
                />
                <div
                  className="eye-icon-container"
                  onClick={handleConfirmPassword}
                >
                  <EyeIcon />
                </div>
              </div>
              

              <div className="form-field terms">
                <label htmlFor="terms">
                  <input type="checkbox" name="agree"  checked={user.agree === "yes" } value="yes" id="terms" onChange={handleChange} /> i
                  agree to the <span>terms and condition</span>
                </label>
              </div>
              <div className="form-field">
                <div className="cta-btn" onClick={handleNextPage}>
                  sign up
                </div>
              </div>

              <div className="flex items-center justify-center">
                <p className="text-center font-[600]">
                  I Already have an account ?{" "}
                </p>
                <Link to="/login" className="text-[#2c7e54] font-[600]">
                  login
                </Link>
              </div>
            </div>
            <div className="flex-item right-side hidden xl:block">
              <div className="img-container">
                <img
                  src={`/assets/auth-rightside-img.svg`}
                  alt="women holding books"
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </section>
  );
}

export default Register;
