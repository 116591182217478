import React from 'react'

const DownloadBookIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path d="M19.9997 25.413C19.8202 25.413 19.6547 25.3837 19.503 25.3251C19.3513 25.2662 19.2027 25.1641 19.0572 25.0188L14.6922 20.6534C14.5297 20.4912 14.4447 20.3 14.4372 20.0797C14.4297 19.8597 14.5147 19.6577 14.6922 19.4738C14.8758 19.2902 15.0738 19.1968 15.2863 19.1934C15.4991 19.1904 15.6973 19.2806 15.8809 19.4643L19.1663 22.7497V9.16634C19.1663 8.92912 19.2459 8.73092 19.4051 8.57176C19.5643 8.41259 19.7625 8.33301 19.9997 8.33301C20.2369 8.33301 20.4351 8.41259 20.5943 8.57176C20.7534 8.73092 20.833 8.92912 20.833 9.16634V22.7497L24.1184 19.4643C24.2806 19.302 24.4734 19.2172 24.6968 19.2097C24.9201 19.2022 25.1236 19.2902 25.3072 19.4738C25.4847 19.6577 25.575 19.8544 25.578 20.0638C25.5813 20.2733 25.4911 20.4698 25.3072 20.6534L20.9422 25.0188C20.7966 25.1641 20.648 25.2662 20.4963 25.3251C20.3447 25.3837 20.1791 25.413 19.9997 25.413ZM11.0255 31.6663C10.2583 31.6663 9.61773 31.4094 9.10384 30.8955C8.58995 30.3816 8.33301 29.7411 8.33301 28.9738V25.7688C8.33301 25.5316 8.41259 25.3334 8.57176 25.1743C8.73092 25.0151 8.92912 24.9355 9.16634 24.9355C9.40356 24.9355 9.60176 25.0151 9.76092 25.1743C9.92009 25.3334 9.99967 25.5316 9.99967 25.7688V28.9738C9.99967 29.2305 10.1065 29.4656 10.3201 29.6793C10.5337 29.8929 10.7688 29.9997 11.0255 29.9997H28.9738C29.2305 29.9997 29.4656 29.8929 29.6793 29.6793C29.8929 29.4656 29.9997 29.2305 29.9997 28.9738V25.7688C29.9997 25.5316 30.0793 25.3334 30.2384 25.1743C30.3976 25.0151 30.5958 24.9355 30.833 24.9355C31.0702 24.9355 31.2684 25.0151 31.4276 25.1743C31.5868 25.3334 31.6663 25.5316 31.6663 25.7688V28.9738C31.6663 29.7411 31.4094 30.3816 30.8955 30.8955C30.3816 31.4094 29.7411 31.6663 28.9738 31.6663H11.0255Z" fill="#1F1F1F"/>
  </svg>
  )
}

export default DownloadBookIcon