
export const downLoadPdf = (pdfUrl,fileName="1stclassmaterialDoc.pdf") => {

const link = document.createElement("a");

// attttibutes
link.href = pdfUrl;
link.download = fileName
link.target = "_blank";
link.rel = "noopener noreferrer";

// Append the link to the body (required for Firefox)
document.body.appendChild(link);

//method to trigger download
link.click();

// Clean up by removing the link element from the DOM
document.body.removeChild(link);
}
