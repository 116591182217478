import React from 'react'

function ArrowDownIcon() {
  return (
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.625 16.2311L6.625 10.2311L8.025 8.83105L12.625 13.4311L17.225 8.83105L18.625 10.2311L12.625 16.2311Z" fill="black"/>
</svg>

  )
}

export default ArrowDownIcon