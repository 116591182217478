import React, { useEffect, useState } from 'react'
import AddMaterialCard from '../../shared/AddMaterialCard';
import AddMaterialIcon from '../icons/AddMaterialIcon';
import AddMaterialModal from '../../Modals/AddMaterialModal';
import CloseModalIcon from '../icons/CloseModalIcon';
import { Toaster,toast } from 'react-hot-toast';
import BookCard from '../../shared/BookCard';
import LoadingSpinner from "../../shared/LoadingSpinner"

function StudyShareIndex(
  {
   contributesNo,
   books,
   fetchingStatus
  }
) {
  const[modal,setModal] = useState(false);


  // Hnadle Open and Closing of Modal
  const handleModal = () => {
    setModal(prev => ! prev)
  }

  const foooterBtns = [
    {
      label:"Cancel",
      type:"cancel"
    },
    {
      label:"Publish",
      type:"submit"
    },
  ]
  return (
    <div>
      {/* Modals */}
      {
        modal && (
        <AddMaterialModal 
        title={"Study share"}
        closeModal={handleModal}
        closeModalIcon={<CloseModalIcon/>}
        footerBtns={foooterBtns}
        />)
      }
      <h6 className='font-[600] text-[18px] mb-[16px]'>Recent uploads</h6>
      {/* React hot Toast */}
      <Toaster position="top-center" reverseOrder={false}></Toaster>

       <div className="flex flex-wrap gap-[8px] lg:gap-[34px]">
        <AddMaterialCard 
        contributes={contributesNo}
        text={"Add material"}
        addMaterialIcon={<AddMaterialIcon/>}
        openMaterialModal={handleModal}
       />
         {
          fetchingStatus ? <LoadingSpinner stroke="var(--green-color)"/> : (
            books.map(book => (
              <BookCard book={book} />
          ))
          )
         }
       </div>
      {/* <BookCard books={books}/> */}
    </div>
  )
}

export default StudyShareIndex