import React from 'react'

function ServicesActiveIcon() {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 11C4.732 11 4.49833 10.9003 4.299 10.701C4.09967 10.5017 4 10.268 4 10V5C4 4.732 4.09967 4.49833 4.299 4.299C4.49833 4.09967 4.732 4 5 4H10C10.268 4 10.5017 4.09967 10.701 4.299C10.9003 4.49833 11 4.732 11 5V10C11 10.268 10.9003 10.5017 10.701 10.701C10.5017 10.9003 10.268 11 10 11H5ZM5 20C4.732 20 4.49833 19.9003 4.299 19.701C4.09967 19.5017 4 19.268 4 19V14C4 13.732 4.09967 13.4983 4.299 13.299C4.49833 13.0997 4.732 13 5 13H10C10.268 13 10.5017 13.0997 10.701 13.299C10.9003 13.4983 11 13.732 11 14V19C11 19.268 10.9003 19.5017 10.701 19.701C10.5017 19.9003 10.268 20 10 20H5ZM14 11C13.732 11 13.4983 10.9003 13.299 10.701C13.0997 10.5017 13 10.268 13 10V5C13 4.732 13.0997 4.49833 13.299 4.299C13.4983 4.09967 13.732 4 14 4H19C19.268 4 19.5017 4.09967 19.701 4.299C19.9003 4.49833 20 4.732 20 5V10C20 10.268 19.9003 10.5017 19.701 10.701C19.5017 10.9003 19.268 11 19 11H14ZM14 20C13.732 20 13.4983 19.9003 13.299 19.701C13.0997 19.5017 13 19.268 13 19V14C13 13.732 13.0997 13.4983 13.299 13.299C13.4983 13.0997 13.732 13 14 13H19C19.268 13 19.5017 13.0997 19.701 13.299C19.9003 13.4983 20 13.732 20 14V19C20 19.268 19.9003 19.5017 19.701 19.701C19.5017 19.9003 19.268 20 19 20H14Z" fill="white"/>
</svg>
  )
}

export default ServicesActiveIcon