import React from 'react'

const PhotoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path d="M20 27.8847C21.7414 27.8847 23.2131 27.2837 24.415 26.0817C25.6169 24.8798 26.2179 23.4081 26.2179 21.6667C26.2179 19.9254 25.6169 18.4537 24.415 17.2517C23.2131 16.0498 21.7414 15.4488 20 15.4488C18.2586 15.4488 16.7869 16.0498 15.585 17.2517C14.3831 18.4537 13.7821 19.9254 13.7821 21.6667C13.7821 23.4081 14.3831 24.8798 15.585 26.0817C16.7869 27.2837 18.2586 27.8847 20 27.8847ZM20 26.218C18.705 26.218 17.6228 25.7831 16.7533 24.9134C15.8836 24.044 15.4487 22.9617 15.4487 21.6667C15.4487 20.3717 15.8836 19.2895 16.7533 18.4201C17.6228 17.5504 18.705 17.1155 20 17.1155C21.295 17.1155 22.3772 17.5504 23.2467 18.4201C24.1164 19.2895 24.5513 20.3717 24.5513 21.6667C24.5513 22.9617 24.1164 24.044 23.2467 24.9134C22.3772 25.7831 21.295 26.218 20 26.218ZM7.6925 33.3334C6.92528 33.3334 6.28472 33.0765 5.77083 32.5626C5.25694 32.0487 5 31.4081 5 30.6409V12.6926C5 11.9254 5.25694 11.2848 5.77083 10.7709C6.28472 10.257 6.92528 10.0001 7.6925 10.0001H12.6217L15.705 6.66675H24.295L27.3783 10.0001H32.3075C33.0747 10.0001 33.7153 10.257 34.2292 10.7709C34.7431 11.2848 35 11.9254 35 12.6926V30.6409C35 31.4081 34.7431 32.0487 34.2292 32.5626C33.7153 33.0765 33.0747 33.3334 32.3075 33.3334H7.6925ZM7.6925 31.6667H32.3075C32.6067 31.6667 32.8525 31.5706 33.045 31.3784C33.2372 31.1859 33.3333 30.9401 33.3333 30.6409V12.6926C33.3333 12.3934 33.2372 12.1476 33.045 11.9551C32.8525 11.7629 32.6067 11.6667 32.3075 11.6667H26.6475L23.5738 8.33341H16.4263L13.3525 11.6667H7.6925C7.39333 11.6667 7.1475 11.7629 6.955 11.9551C6.76278 12.1476 6.66667 12.3934 6.66667 12.6926V30.6409C6.66667 30.9401 6.76278 31.1859 6.955 31.3784C7.1475 31.5706 7.39333 31.6667 7.6925 31.6667Z" fill="white"/>
  </svg>
  )
}


export default PhotoIcon