import React from 'react'
import { Link } from 'react-router-dom'

function PassportGenerator() {
  return (
    <div className='passport-container mt-20'>
      <div className="lg:flex text-center ">
        <div className="flex-item  basis-[38%]">
        <h4 className='lowercase'>Take your passport from anywhere</h4>
        <p className='mb-8 text-[#606060]'>Simply take a portrait selfie with your phone. Leave the rest to us</p>
        </div>
      </div>
      <div className="lg:flex text-center mb-12">
        <div className="flex-item mb-8 order-2 basis-[38%] ml-auto">
        <span className="icon-container inline-block">
            <img src={`/assets/camera-icon.svg`} alt="camera icon" />
         </span>
         <div className="form-field">
            <input type="file" id='file' className='hidden'  accept='image/*'/>
            <label className='bg-[#ffe24b] p-4 rounded-[8px] w-auto' htmlFor="file">upload photo 
              <span className='upload-icon inline-block align-middle mr-2 ml-4'>
                <img src={`/assets/upload-photo-icon.svg`} alt="upload icon" />
              </span></label>
          </div>
            <h5>upload your picture</h5>
            <p>Pick an image to to serve as your passport</p>
        </div>
        <div className="flex-item basis-[48%]">
        <div className="img-container">
          <img src={`/assets/passport-gallery.svg`} alt="passport gallery" />
        </div>
        </div>
      </div>
      <div className="how-it-works">
        <h4 className='text-center flex justify-center relative'>How it works 
          <span className='curved-line inline-block absolute bottom-0'>
            <img src={`/assets/curved-line.svg`} alt="curved line" />
          </span></h4>
      </div>
    </div>
  )
}

export default PassportGenerator