import React from 'react'

function LogoutIcon() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <path d="M5.9905 20.2109C5.53017 20.2109 5.14583 20.0568 4.8375 19.7484C4.52917 19.4401 4.375 19.0558 4.375 18.5954V5.82644C4.375 5.3661 4.52917 4.98177 4.8375 4.67344C5.14583 4.3651 5.53017 4.21094 5.9905 4.21094H12.3942V5.21094H5.9905C5.8365 5.21094 5.69542 5.27502 5.56725 5.40319C5.43908 5.53135 5.375 5.67244 5.375 5.82644V18.5954C5.375 18.7494 5.43908 18.8905 5.56725 19.0187C5.69542 19.1469 5.8365 19.2109 5.9905 19.2109H12.3942V20.2109H5.9905ZM16.8365 15.7494L16.1345 15.0302L18.4538 12.7109H9.56725V11.7109H18.4538L16.1345 9.39169L16.8365 8.67244L20.375 12.2109L16.8365 15.7494Z" fill="black"/>
</svg>
  )
}

export default LogoutIcon