import React from 'react'

function LeftArrowIcon() {
  return (
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5355 20.8334L20.5993 29.8976C20.7618 30.0598 20.8468 30.2511 20.8543 30.4713C20.8618 30.6913 20.7736 30.8933 20.5897 31.0772C20.4061 31.2544 20.2095 31.3447 20.0001 31.348C19.7907 31.3511 19.5941 31.2608 19.4105 31.0772L9.27593 20.9426C9.13038 20.797 9.02829 20.6484 8.96968 20.4968C8.91079 20.3451 8.88135 20.1795 8.88135 20.0001C8.88135 19.8206 8.91079 19.6551 8.96968 19.5034C9.02829 19.3518 9.13038 19.2031 9.27593 19.0576L19.4105 8.92301C19.5663 8.76718 19.7559 8.68384 19.9793 8.67301C20.2026 8.66245 20.4061 8.74579 20.5897 8.92301C20.7736 9.1069 20.8655 9.30509 20.8655 9.51759C20.8655 9.73037 20.7736 9.92856 20.5897 10.1122L11.5355 19.1668H30.8334C31.0707 19.1668 31.2689 19.2463 31.428 19.4055C31.5872 19.5647 31.6668 19.7629 31.6668 20.0001C31.6668 20.2373 31.5872 20.4355 31.428 20.5947C31.2689 20.7538 31.0707 20.8334 30.8334 20.8334H11.5355Z" fill="black"/>
</svg>

  )
}

export default LeftArrowIcon