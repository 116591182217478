import React from 'react'

function StudyShareIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 19H8.673V11H4V19ZM9.673 19H14.327V5H9.673V19ZM15.327 19H20V13H15.327V19ZM3 18.3845V11.6155C3 11.1712 3.15817 10.7908 3.4745 10.4745C3.79083 10.1582 4.17117 10 4.6155 10H8.673V5.6155C8.673 5.17117 8.83117 4.79083 9.1475 4.4745C9.46383 4.15817 9.84417 4 10.2885 4H13.7115C14.1558 4 14.5362 4.15817 14.8525 4.4745C15.1688 4.79083 15.327 5.17117 15.327 5.6155V12H19.3845C19.8288 12 20.2092 12.1582 20.5255 12.4745C20.8418 12.7908 21 13.1712 21 13.6155V18.3845C21 18.8288 20.8418 19.2092 20.5255 19.5255C20.2092 19.8418 19.8288 20 19.3845 20H4.6155C4.17117 20 3.79083 19.8418 3.4745 19.5255C3.15817 19.2092 3 18.8288 3 18.3845Z" fill="#00140A"/>
    </svg>
  )
}

export default StudyShareIcon