import React from 'react'

function AddMaterialCard(
    {
        contributes = 0,
        addMaterialIcon,
        text,
        openMaterialModal
    }
) {
  return (
    <div className='add-material-card inline-block rounded-[12px] p-[1rem] cursor-pointer basis-[175px] h-[220px] lg:h-[309px] lg:basis-[253px] bg-[#ffe24b]' onClick={() => openMaterialModal()}>
        <figure className=''>
            <div className="card-header mb-[63px]">
            <h4 className='flex items-baseline'>{contributes} <span className='font-[500] text-[12px]  text-[#7d7100]'>CONTRIBUTES</span></h4>
            </div>
            <div className="card-body flex flex-col justify-center items-center">
                <span className="icon-container inline-block">
                    {addMaterialIcon}
                </span>
                <p className='font-[500] text-[#000] text-[14px]'>{text}</p>
            </div>

        </figure>
    </div>
  )
}

export default AddMaterialCard