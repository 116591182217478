import React from 'react'

const UploadFileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path d="M14.3525 21.9669H15.5471V16.3594L18.0556 18.8679L18.9011 18.0156L14.9498 14.0643L10.9985 18.0156L11.8508 18.8611L14.3525 16.3594V21.9669ZM8.51769 25.8262C7.9678 25.8262 7.50869 25.6421 7.14037 25.2737C6.77205 24.9054 6.58789 24.4463 6.58789 23.8964V6.25402C6.58789 5.70413 6.77205 5.24502 7.14037 4.8767C7.50869 4.50838 7.9678 4.32422 8.51769 4.32422H17.9362L23.3117 9.69972V23.8964C23.3117 24.4463 23.1275 24.9054 22.7592 25.2737C22.3909 25.6421 21.9318 25.8262 21.3819 25.8262H8.51769ZM17.3389 10.297V5.51877H8.51769C8.33373 5.51877 8.1652 5.59533 8.0121 5.74843C7.859 5.90153 7.78245 6.07006 7.78245 6.25402V23.8964C7.78245 24.0804 7.859 24.2489 8.0121 24.402C8.1652 24.5551 8.33373 24.6317 8.51769 24.6317H21.3819C21.5658 24.6317 21.7344 24.5551 21.8875 24.402C22.0406 24.2489 22.1171 24.0804 22.1171 23.8964V10.297H17.3389Z" fill="#808080"/>
  </svg>
  )
}

export default UploadFileIcon