import React, { useEffect, useState } from 'react'
import { toast, Toaster } from 'react-hot-toast';
import LoadingSpinner from '../shared/LoadingSpinner';
import DashboardHeader from './DashboardHeader';
import DashboardSideMenu from './Sidemenu/DashboardSideMenu';
import { Outlet, useNavigate } from 'react-router-dom';

function DashboardLayout() {
  const navigate = useNavigate("");
  const[userData,setUserData] = useState({});
  // const[resultData,setResultData] = useState([]);
  const[loading,setLoading] = useState(false);


  // store the user data in local storage
  const addToLocalStorage = data => {
    localStorage.setItem("userData",JSON.stringify(data));
  }

  // Handle Logout
  function handleLogout() {
  localStorage.removeItem("isAuthenticated_1stclassmaterial_user");
  }

  useEffect(()=>{
   const fetchUserData =  async() => {
    setLoading(true);
    try{
      const url = process.env.REACT_APP_FETCH_USER;
      const res =  await fetch(url,{ headers:{"Authorization":`Bearer ${localStorage.getItem("login_token")}`}});
      const resObj = await res.json();


      // Check if the user account does not exist || maybe has been deleted
      if(res.status === 404){
        localStorage.removeItem("isAuthenticated_1stclassmaterial_user");
        toast.success(resObj.message);
        setTimeout(()=>{
          navigate("/login")
        },3000)
      }

      // if response is not okay throw this
      if(!res.ok){
       throw new Error(resObj.message)
      }

      setUserData(resObj?.data)
      addToLocalStorage(resObj?.data);
      toast.success(resObj.message);
      setLoading(false)
    }
    catch(err){
      toast.error(err.message);
      setLoading(false)
    }
   }
   fetchUserData();
  },[])


  if(loading) {
    return (
      <div className='flex flex-wrap bg-[var(--black-color)] h-screen flex-row items-center justify-center'>
        <div className="flex-item m-auto">
        <span className='block text-center'><LoadingSpinner/></span>
        <p className='text-[#fff]'>Loading users data</p>
        </div>
      </div>
    )
  }

  return (
    <div className='dashboard-layout'>
      <DashboardHeader userData={userData} profileImage={userData?.profileImg} handleLogout={handleLogout} />
      <div className="grid-container">
        <div className="grid-item hidden lg:block">
        <DashboardSideMenu/>
        </div>
        <div className="grid-item lg:pr-6">main contents<Outlet/></div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  )
}

export default DashboardLayout