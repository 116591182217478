import React, { useState } from 'react'
import {toast,Toaster }from 'react-hot-toast';
import LoadingSpinner from '../../shared/LoadingSpinner';

const FeedBackIndex = () => {

    const[loading,setLoading] = useState(false);
    const[message,setMessage] =  useState("")


    // Handle Change
    const handleChange = (e) => {
     setMessage(e.target.value)
    }

    // Handle Submit
    const handleSubmit = async(e) => {
     setLoading(true)
     e.preventDefault();
     const url = process.env.REACT_APP_FEED_BACK;
     const data = {
        message,
     }

    console.log(url);
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization":`Bearer ${localStorage.getItem("login_token")}`
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }
      toast.success(resObj?.message);
      setMessage("")
    }
     catch(err){
      toast.error(`Error sending feedback : ${err.message}`)
     }
     finally{
      setLoading(false);
     }
    }

  return (
    <div className="lg:flex">
        <div className="flex-item">
            <h5>share your thoughts</h5>
            <p className='mb-[32px]'>Thank you for taking the time to share your experience with us. Your feedback helps us improve and provide a better service. Whether you have suggestions, encountered an issue, or just want to let us know how we’re doing, we’d love to hear from you.</p>
            <p>What's your feedback ?</p>
            <form onSubmit={handleSubmit}>
            <Toaster position="top-center" reverseOrder={false}></Toaster>
                <div className="form-field">
                    <textarea name="message" id="message" value={message} onChange={handleChange} placeholder='Your message'></textarea>
                </div>
                <div className="btn-container flex justify-end">
                    <button className='cta-btn active'>
                        {
                          loading ? <LoadingSpinner/> : "Send"
                        }
                    </button>
                </div>
            </form>
        </div>
        <div className="flex-item"></div>
    </div>
  )
}

export default FeedBackIndex