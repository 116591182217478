import React from 'react'

function FeedBackActiveIcon() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M7.19225 13.6155H8.2655C8.37317 13.6155 8.47342 13.5968 8.56625 13.5595C8.65925 13.5223 8.74933 13.4602 8.8365 13.373L13.8577 8.34625C13.9436 8.24742 14.008 8.14383 14.051 8.0355C14.094 7.92717 14.1155 7.81983 14.1155 7.7135C14.1155 7.607 14.094 7.5015 14.051 7.397C14.008 7.29267 13.9455 7.19108 13.8635 7.09225L12.9385 6.14225C12.8525 6.05642 12.7532 5.992 12.6405 5.949C12.5277 5.906 12.4161 5.8845 12.3058 5.8845C12.1994 5.8845 12.093 5.90283 11.9865 5.9395C11.8802 5.976 11.7776 6.04358 11.6788 6.14225L6.627 11.1635C6.53983 11.2507 6.47767 11.3408 6.4405 11.4338C6.40317 11.5266 6.3845 11.6268 6.3845 11.7345V12.8077C6.3845 13.0398 6.46117 13.2323 6.6145 13.3855C6.76767 13.5388 6.96025 13.6155 7.19225 13.6155ZM12.3 8.6885L11.35 7.74425L12.3058 6.7885L13.2308 7.7385L12.3 8.6885ZM11.8155 13.6155H17.1155C17.2577 13.6155 17.3765 13.5677 17.472 13.472C17.5677 13.3765 17.6155 13.2577 17.6155 13.1155C17.6155 12.9732 17.5677 12.8542 17.472 12.7587C17.3765 12.6632 17.2577 12.6155 17.1155 12.6155H12.8155L11.8155 13.6155ZM6.077 17L4.373 18.7038C4.1205 18.9564 3.8285 19.0145 3.497 18.878C3.16567 18.7413 3 18.4929 3 18.1328V4.6155C3 4.15517 3.15417 3.77083 3.4625 3.4625C3.77083 3.15417 4.15517 3 4.6155 3H19.3845C19.8448 3 20.2292 3.15417 20.5375 3.4625C20.8458 3.77083 21 4.15517 21 4.6155V15.3845C21 15.8448 20.8458 16.2292 20.5375 16.5375C20.2292 16.8458 19.8448 17 19.3845 17H6.077Z" fill="white"/>
</svg>
  )
}

export default FeedBackActiveIcon