import React from 'react'

function Notifications() {
  return (
    <div className='notifications-container'>
            <div className='mb-4'>
                <p className='font-[500]'>Recommended books</p>
                <div className="flex gap-8">
                <p className='text-[0.75rem]'>Notify me on books tailored to my my field of study</p>
                <button className='enable-btn basis-[22%] ml-auto'>
                    <span></span>
                </button>
                </div>
            </div>
            <div>
                <p>Up comming webinars</p>
                <div className="flex gap-8">
                <p className='text-[0.75rem]'>Notify me if a webinar’s date is due</p>
                <button className='enable-btn basis-[22%] ml-auto'>
                    <span></span>
                </button>
                </div>
            </div>
    </div>
  )
}

export default Notifications
