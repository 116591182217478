import React, { useState } from "react";
// import PasswordChecklist from "react-password-checklist"
import { toast, Toaster } from "react-hot-toast";
import LoadingSpinner from "../../shared/LoadingSpinner";
// import DragAndDrop from "./DragAndDrop";
import UploadFileIcon from "../icons/UploadFileIcon";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddMaterialPlusIcon from "../icons/AddMaterialPlusIcon";


// invoke the GlobalWorker Option
GlobalWorkerOptions.workerSrc = pdfjsWorker;
 const defaultThumbnail = `https://res.cloudinary.com/dixtysymz/image/upload/c_thumb,w_200,g_face/v1731539316/ebooks/default-thumbnail_ap4bzf.png`

function StudyShareForm({ footerBtns, closeModal }) {
  const maxFileSize = 10 * 1024 * 1024;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
   // State to hold a single file object
   const [file, setFile] = useState(null);
   const [thumbNail, setThumbNail] = useState(null);
   const [material, setMaterial] = useState({
    courseTitle : "",
    courseType: "",
    courseCode: "",
    department: "",
    level: "",
  });

  // Handle the stacking order of added books
    const [stackedBooks,setStackedBooks]  = useState([]);


    // const addToStackedBooks = (newBook) => {
    //   if (
    //     !material.courseTitle||
    //     !material.courseType ||
    //     !material.courseCode ||
    //     !material.department ||
    //     !material.level ||
    //     !file ||
    //     !thumbNail 
    //   ) {
    //     toast.error(
    //       "Please fill the necessary details to add to stack..!",
    //     );
    //     return;
    //   }

    //   // check if the stacked book exist with an id
    //   setStackedBooks([...stackedBooks,{...newBook, thumbNail, id:crypto.randomUUID()}]);

    //   //Set the fields empty
    //   setMaterial(prev => ({
    //     ...prev,
    //     courseTitle : "",
    //     courseType: "",
    //     courseCode: "",
    //     department: "",
    //     level: "",
    //   }))
    // }
    const addToStackedBooks = () => {
      if (!material.courseTitle || !material.courseType || !material.courseCode || !material.department || !material.level || !file || !thumbNail) {
        toast.error("Please fill the necessary details to add to stack..!");
        return;
      }
      
      setStackedBooks([...stackedBooks, { ...material, thumbNail, id: crypto.randomUUID() }]);
      setMaterial({
        courseTitle: "",
        courseType: "",
        courseCode: "",
        department: "",
        level: ""
      });
      setFile(null); // Reset file state
      setThumbNail(null); // Reset thumbnail
    };
    

    // function handleStackedBooks(){
    //   return JSON.parse()
    // }


    // console.log(stackedBooks?.length)
    // console.log(stackedBooks)




  // Cloudinary file upload
  const cloudName = process.env.REACT_APP_CLOUD_NAME;
  const cloudinaryUploadPreset = process.env.REACT_APP_EBOOKS_UPLOAD_PRESET;

  const uploadToCloudinary = async (file, resourceType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", cloudinaryUploadPreset);

    try {
      let apiKey = `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`;
      const response = await axios.post(apiKey, formData);
      const { secure_url } = response?.data;
      return secure_url;
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Handle Change
  const handleChange = (e) => {
    setMaterial((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Revised handleFileChange
const handleFileChange = async (event) => {
  const selectedFile = event.target.files[0];

  if(selectedFile?.size > maxFileSize) {
   toast.error("File size exceeds 10 MB");
    return;
  }

  setFile(selectedFile);

  if (selectedFile && selectedFile.type === "application/pdf") {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const pdfData = new Uint8Array(event.target.result);
      const pdf = await getDocument(pdfData).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement("canvas");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const context = canvas.getContext("2d");
      await page.render({ canvasContext: context, viewport }).promise;
      setThumbNail(canvas.toDataURL()); // Set thumbNail for image preview
    };
    reader.readAsArrayBuffer(selectedFile);
  } 
  else {
    // Handle other file types as needed
    setThumbNail(defaultThumbnail);
  }
};

// Revised handleSubmit with additional checks
const handleSubmit = async (event) => {
  event.preventDefault();
  if (
    !material.courseTitle ||
    !material.courseType ||
    !material.courseCode ||
    !material.department ||
    !material.level ||
    !file ||
    !thumbNail 
  ) {
    toast.error("Please provide the necessary details to upload document..!");
    return;
  }
  
  setLoading(true);
  
  // Upload to Cloudinary
  const imgUrl = thumbNail === defaultThumbnail ? thumbNail : await uploadToCloudinary(thumbNail, "image");
  console.log(`Image Url ${imgUrl}`)
  const pdfUrl = await uploadToCloudinary(file, "raw");

  if (!pdfUrl || !imgUrl) {
    toast.error("Failed to upload document. Please try again.");
    setLoading(false);
    return; // Stop if URLs are not returned
  }

  const data = {
    courseTitle: material.courseTitle,
    courseType: material.courseType,
    courseCode: material.courseCode,
    department: material.department,
    level: material.level,
    docURL: pdfUrl, // Ensure docURL is present
    thumbNail: imgUrl, // Ensure thumbnail is present
  };

  try {
    const response = await fetch(process.env.REACT_APP_BOOKS, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("login_token")}`,
      },
      body: JSON.stringify(data),
    });
    
    const resObj = await response.json();

    if (!response.ok) throw new Error(resObj?.message);
    toast.success("Document uploaded successfully!");

    setMaterial({
      courseTitle: "",
      courseType: "",
      courseCode: "",
      department: "",
      level: ""
    });
    setTimeout(() => {
      navigate("/dashboard");
      window.location.href = '/dashboard';
    },
    2000);
  } catch (err) {
    toast.error(`An error occurred during upload : ${err?.message}`);
    // console.error("Upload error:", err);
  } finally {
    setLoading(false);
  }
};


  // console.log(thumbNail)
  return (
    <div className="modal-body lg:grid grid-cols-[97px,1fr] lg:gap-[40px]">
    <div className="grid-items mb-[32px]">
        {/* <UploadFileButtons addToStackedBooks/> */}
        <div className='flex lg:flex-col gap-4'>
        <div className="flex-item">
                {
                  stackedBooks?.length === 0 ? (
                    <span className="icon-contianer cursor-pointer flex justify-center items-center w-[50px] h-[50px] border rounded-[4px]">
                    <UploadFileIcon/>
                    </span>
                  )
                  :
                  stackedBooks.map(item  => (
                   <div key={item?.id} className="flex gap-4 w-[45px] h-[45px] mb-4 border border-[#f2f2f2] cursor-pointer">
                     <div key={item?.id} className="">
                      <img src={item?.thumbNail} alt={item?.courseTitle} />
                    </div>
                   </div>
                  ))
                }
            
        </div>
        <div className="flex-item flex w-[50px] h-[50px] border rounded-[4px]">
        <span 
        onClick={()=>addToStackedBooks(material)}
        className="icon-contianer cursor-pointer flex justify-center items-center w-[50px] h-[50px] border rounded-[4px]">
            <AddMaterialPlusIcon/>
        </span>
        </div>
    </div>
    </div>
    <div className="grid-items studyshare-form overflow-y-auto">
     {/* <StudyShareForm footerBtns={footerBtns} closeModal={closeModal}/> */}
     <form onSubmit={handleSubmit}>
      {/* React hot Toast */}
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      {/* Back Arrow */}
      <div className="lg:flex">
        <div className="flex-item mb-[1rem] lg:mb-0 justify-self-center lg:justify-self-auto">

          {/* Drag and Drop Section */}
           {
            thumbNail ? (
              <div className="inline-block text-center h-[170px] w-[154px] rounded-[6px] bg-[#f2f2f2]">
                <div className="form-field">
                  <input
                    type="file"
                    className="hidden"
                    id="docURL"
                    name="docURL"
                    accept=".pdf, .doc, .docx, .ppt, .pptx"
                    onChange={handleFileChange}
                  />
                  <label
                    className="rounded-[8px] font-[500] cursor-pointer"
                    htmlFor="docURL"
                  >
                   <img className="inline-block h-[180px]" src={thumbNail} alt="PDF Preview"/>
                  </label>
                </div>
            </div>
            ) :
            (
              <div className="inline-block flex text-center h-[170px] w-[154px] rounded-[6px] bg-[#f2f2f2]">
              <figure className="m-auto">
                <div className="form-field">
                  <input
                    type="file"
                    className="hidden"
                    id="docURL"
                    name="docURL"
                    accept=".pdf, .doc, .docx, .ppt, .pptx"
                    onChange={handleFileChange}
                  />
                  <label
                    className="rounded-[8px] font-[500] cursor-pointer"
                    htmlFor="docURL"
                  >
                    <span className="flex justify-center mb-[6px]">
                      <UploadFileIcon />
                    </span>
                    choose a file to drop here
                  </label>
                </div>
              </figure>
            </div>
            )
           }
        </div>

        <div className="flex-item basis-[100%] lg:basis-[72%] ml-auto webkit-scrollbar lg:max-h-[320px] overflow-y-auto">
          <div className="form-field">
            <label htmlFor="courseTitle">title</label>
            <input
              type="text"
              id="courseTitle"
              name="courseTitle"
              placeholder="Add a Title"
              value={material.courseTitle}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="courseType">type</label>
            <div className="select-field">
              <select name="courseType" id="courseType" onChange={handleChange}>
                <option value="">Please select a type</option>
                <option value="text books">text books</option>
                <option value="lecture notes">lecture notes</option>
                <option value="fillers">fillers</option>
                <option value="past questions">past questions</option>
                <option value="summaries">summaries</option>
              </select>
            </div>
          </div>
          <div className="form-field">
            <label htmlFor="courseCode">course code</label>
            <input
              type="text"
              name="courseCode"
              id="courseCode"
              value={material.courseCode}
              onChange={handleChange}
              placeholder="Ex: GSP 101"
            />
          </div>
          <div className="form-field">
            <label htmlFor="department">department</label>
            <input
              type="text"
              name="department"
              id="department"
              value={material.department}
              onChange={handleChange}
              placeholder="Ex: Electronic engineering"
            />
          </div>
          <div className="form-field">
            <label htmlFor="level">level</label>
            <div className="select-field">
              <select name="level" id="level" onChange={handleChange}>
                <option value="">Please select your level</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="600">600</option>
                <option value="700">700</option>
              </select>
            </div>
          </div>

          <div className="form-field footer-btns">
            {/* <button className="cta-btn" disabled={loading}>
                      {loading ? <LoadingSpinner /> : "proceed"}
                    </button> */}
            <div className="btn-container flex justify-end">
              {footerBtns.map((btn) => (
                <button
                  className={`ml-2 ${btn.type === "cancel" ? "cta-btn" : "cta-btn active"}`}
                  key={btn.label}
                  onClick={btn.type === "cancel" ? closeModal : null}
                >
                  {btn.type === "submit" && loading ? (
                    <LoadingSpinner />
                  ) : (
                    `${btn.label}`
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
</div>
    
  );
}
export default StudyShareForm;
