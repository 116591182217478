import React, { useEffect, useState } from 'react'

function PasswordStrengthCheck({passwordVal}) {
    const [progress,setProgress]  = useState("");
    const[message,setMessage] = useState("")

    function getActiveColor(type) {
        let activeType = type === "Strong" ? "#3fbb68" : type === "Medium" ? "#fe8040" : "#ff0054"
        return activeType;
       
       //  if(type === "Strong") return "#3fbb68" ;
       //  if(type === "Medium") return "#fe8040";
       //  return "#ff0054";
       } 

    useEffect(()=> {
           // create an object to track password strength checks
           const strengthChecks = {
               length:0,
               hasUpperCase:false,
               hasLowerCase:false,
               hasDigits:false,
               hasSpecialChar:false
           }
           
           // update the strength checks based on the password value
           strengthChecks.length = passwordVal.length >= 8 ? true : false;
           strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordVal);
           strengthChecks.hasLowerCase = /[a-z]+/.test(passwordVal);
           strengthChecks.hasDigits = /[0-9]+/.test(passwordVal);
           strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordVal);
           
           // Create a list of verified checks
           let verifiedLists = Object.values(strengthChecks).filter(val => val);
        //    console.log(verifiedLists)
           
           //Determine the strength of the passsword based on  verified checks
           let strength = verifiedLists.length === 5 ?
           "Strong"
           : verifiedLists.length >= 2 
           ? "Medium" 
           : "Weak"
           
           // Update the password, progress and message state variables
           setProgress(`${(verifiedLists.length / 5) * 100}% `);
           setMessage(strength)
    },[passwordVal])

    // console.log(passwordVal)
       
  return (
    <div className="password-check-list">
    <p className='text-[var(--red-color)] mb-4' style={{color:getActiveColor(message)}}>Must be at least 8 characters long, must contain a special letter and an upper case</p>
    <div className="indicator-checklist">
        <span className="indicator" style={{width:progress,background:getActiveColor(message)}}></span>
        <span className="indicator" style={{background:getActiveColor(message)}}></span>
        <span className="indicator" style={{background:getActiveColor(message)}}></span>
        <span className="indicator" style={{background:getActiveColor(message)}}></span>
        <span className="indicator" style={{background:getActiveColor(message)}}></span>
    </div>
    </div>
  )
}

export default PasswordStrengthCheck