import React from 'react'

function FilterPage() {
  return (
    <div>
        <span>filter icon</span>
    </div>
  )
}

export default FilterPage