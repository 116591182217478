import React from 'react'
import { Link } from 'react-router-dom'

function ProfileModal({
    handleLogout,
    userData,
    handleShowProfile
  }) {
  return (
    <div 
    onClick={handleShowProfile}
    className='profile-card-container min-h-[336px] w-[267px] absolute top-16 right-0 rounded-[12px] p-[16px] z-[1040]'>
         <div className="flex items-center gap-x-[7px] mb-[6px]">
          <div className="flex-item">
            <div className="img-container inline-block w-[38px] h-[38px] overflow-hidden rounded-[50%]">
              <img className='inline-block h-full' src={userData?.profileImg} alt="profile -image" />
            </div>
          </div>
          <div className="flex-item">
            <p className='capitalize font-[600]'>{userData?.fullName}</p>
            <p>@{userData?.email?.match(/^[^@]+/)[0]}</p>
          </div>
         </div>
        <ul className="list-container">
        <li className='btn-container mb-[24px]'>
          <Link className='view-profile-btn cta-btn w-full' to="/dashboard/view-profile">View Profile</Link>
        </li>
        <li>
          <Link to="/dashboard/studyshare">studyshare</Link>
        </li>
        <li>
          <Link to="/dashboard/feedback">feedback</Link>
        </li>
        <li>
          <Link className='mb-8' to="/dashboard/settings">settings</Link>
          <hr/>
        </li>
        <li>
                <Link to="/login" className='text-[var(--red-color)]' onClick={() => handleLogout()}>logout</Link>
            </li>
        </ul>
    </div>
  )
}

export default ProfileModal