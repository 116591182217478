
export const convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
  
      // Resolve with Base64 result when loading completes
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
  
      // Reject with error if reading fails
      fileReader.onerror = (error) => {
        reject(error);
      };
  
      fileReader.readAsDataURL(file); // Start reading file as data URL
    });
  };
  